import { authClient, API } from "../index";

// Get all origins
export const getOrigins = () => {
  return authClient().get(`${API}/origin?getDisabled=true`);
};

// Get origin by id
export const getOriginById = (id) => {
  return authClient().get(`${API}/origin/${id}`);
};

// Delete origin
export const deleteOrigin = (id) => {
  return authClient().delete(`${API}/origin/${id}`);
};

// Create origin
export const postOrigin = async (origin) => {
  return authClient().post(`${API}/origin`, origin);
};

// Update origin
export const updateOrigin = async (id, origin) => {
  return authClient().put(`${API}/origin/${id}`, origin);
};

// Count all origins
export const countOrigins = () => {
  const role = "origin";
  return authClient().get(`${API}/origin/count/${role}`);
};

// Change status origin
export const changeStatusOrigin = async (id, active) => {
  return authClient().put(`${API}/origin/change-status/${id}`, { active });
};
