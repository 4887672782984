import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getBrevoCampaigns,
  deleteCampaign,
  changeStatusCampaign,
} from "../../../../api/campaign";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { CircularProgress } from "@material-ui/core";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";

function getData(campaigns) {
  let data = [];
  for (let i = 0; i < campaigns.length; ++i) {
    const elem = {};
    elem.name = campaigns[i].name;
    elem.status = campaigns[i].status;
    elem.scheduledAt = campaigns[i].scheduledAt;
    elem.createdAt = campaigns[i].createdAt;
    elem.id = campaigns[i].id;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function AdminsPage() {
  const [data, setData] = useState([]);
  const [campaignId, setCampaignId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="View-Campaign">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-campaign/" + cell)}
          >
            <VisibilityIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    { dataField: "status", text: "Estado", sort: true },
    {
      dataField: "createdAt",
      text: "Creado el",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "scheduledAt",
      text: "Programado para",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getBrevoCampaigns()
      .then((res) => {
        if (res.status === 200) {
          const allCampaigns = [...res.data?.email?.campaigns].sort(
            (a, b) => a.createdAt - b.createdAt
          );
          setData(allCampaigns);
          setFilteredData(allCampaigns);
          setCampaigns(allCampaigns);
          setRefresh(false);
          postLog("campaigns-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get campaigns.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Campaings list"></CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />
          {data.length === 0 ? (
            <section className="d-flex flex-column justify-content-center align-items-center">
              <h4>Cargando Campañas ... </h4>
              <br />
              <br />
              <CircularProgress size={100} />
            </section>
          ) : (
            <Table data={getData(filteredData)} columns={columns} />
          )}
          <ConfirmDialog
            title={`Are you sure you want to ${
              campaignId?.active ? "disable" : "enable"
            } this campaign?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusCampaign(campaignId.id, !campaignId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    postLog("Campaigns-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${campaignId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Campaign ${
                        campaignId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      campaignId?.active ? "disable" : "enable"
                    } admin.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this campaign?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCampaign(campaignId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Campaign removed successfully.",
                    });
                    setRefresh(true);
                    postLog("Campaigns", "delete", user.fullName);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove admin.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
