import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { authActions } from "./modules/Auth/_redux/authActions";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getCredentials, refreshTokens } from "../api/auth/index";
import ErrorsPage from "./modules/Errors/ErrorsPage";
import { logError } from "../utils/logger";
import { getLanguages } from "../api/language";
import { LayoutSplashScreen } from "../_metronic/layout";
import SignContractPage from "./modules/Auth/pages/SignContractsPage";

export function Routes() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isLogged = useSelector(
    (store) => store.authentication?.loggedIn,
    shallowEqual
  );

  // Checking whether we have credentials in cookies before going to login
  useEffect(() => {
    function fetchMyCredentials() {
      setLoading(true);
      setTimeout(async () => {
        try {
          const response = await getCredentials();
          if (response)
            dispatch(authActions.loginActionSuccess(response?.data));
          const languages = await getLanguages();
          if (languages?.data) {
            dispatch(authActions.getLanguagesSuccess(languages?.data));
            setLoading(false);
          }
        } catch (error) {
          logError({
            error,
            customMessage: "Cannot get credentials, please login.",
          });
          // alertError({error})
          dispatch(authActions.logoutAction());
        }
        setLoading(false);
      }, 1000);
    }
    fetchMyCredentials();
  }, [dispatch]);

  // Refresh interval every 15 minutes if logged
  useEffect(() => {
    if (isLogged) {
      const interval = setInterval(async () => {
        try {
          await refreshTokens();
        } catch (error) {
          logError({ error, customMessage: "Cannot refresh tokens." });
          // alertError({error})
          dispatch(authActions.logoutAction());
        }
      }, 900000);
      return () => clearInterval(interval);
    }
  }, [dispatch, isLogged]);

  return loading ? (
    <LayoutSplashScreen />
  ) : (
    <Switch>
      <Route path="/sign-contract/:id" component={SignContractPage} />

      {!isLogged ? (
        <>
          <Route path="/auth" component={AuthPage} />
          <Redirect to="/auth/login" />
        </>
      ) : (
        <>
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />
          <Layout>
            <BasePage />
          </Layout>
        </>
      )}
    </Switch>
  );
}
