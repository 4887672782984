import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteOrigin,
  getOriginById,
  postOrigin,
  updateOrigin,
} from "../../../../api/origin";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyOrigin() {
  return {
    name: "",
    description: "",
    active: true,
  };
}

export default function EditOriginsPage() {
  const [origin, setOrigin] = useState(getEmptyOrigin());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const originId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!originId) {
      disableLoadingData();
      return;
    }

    getOriginById(originId)
      .then((res) => {
        if (res.status === 200) {
          const origin = res.data;
          setOrigin(origin);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get origin." });
        history.push("/origins");
      });
  }, [originId, disableLoadingData, history]);

  function saveOrigin() {
    let saveOrigin = origin;
    if (!originId) {
      postOrigin(saveOrigin)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Origin successfully created.",
            });
            postLog("Origins", `create-${originId}`, user.fullName);
            history.push("/origins");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save origin.",
          });
        });
    } else {
      updateOrigin(originId, saveOrigin)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Origins", `update-${originId}`, user.fullName);
            history.push("/origins");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setOrigin({ ...origin, [element]: event.target.value });
  };

  useEffect(() => {
    console.log("origin", origin);
  }, [origin]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit origin"></CardHeader>
          <CardBody>
            <TextField
              id={`Nombre`}
              label="Nombre"
              value={origin.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <TextField
              id={`descripcion`}
              label="Descripcion"
              multiline
              minRows={4}
              value={origin.description}
              onChange={handleChange("description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={origin.active}
                  onChange={() =>
                    setOrigin({ ...origin, active: !origin.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/origins")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveOrigin()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save origin
        </Button>
        {originId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete origin
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this origin?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteOrigin(originId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Origin deleted successfully",
                      });
                      postLog("Origins", `delete-${originId}`, user.fullName);
                      history.push("/origins");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete origin.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
