import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteComunidad,
  getComunidadById,
  postComunidad,
  updateComunidad,
} from "../../../../api/comunidad";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { checkIsEmpty } from "../../../../utils/helpers";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyComunidad() {
  return {
    name: "",
    active: true,
  };
}

export default function EditComunidadesPage() {
  const [comunidad, setComunidad] = useState(getEmptyComunidad());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const comunidadId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!comunidadId) {
      disableLoadingData();
      return;
    }
    getComunidadById(comunidadId)
      .then((res) => {
        if (res.status === 200) {
          setComunidad(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la comunidad.",
        });
        history.push("/comunidades");
      });
  }, [comunidadId, disableLoadingData, history]);

  function saveComunidad() {
    if (checkIsEmpty(comunidad.name))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!comunidadId) {
      postComunidad(comunidad)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Comunidad creada correctamente.",
            });
            postLog("Comunidades", `create-${comunidadId}`, user.fullName);
            history.push("/comunidades");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guarda la comunidad.",
          });
        });
    } else {
      updateComunidad(comunidadId, comunidad)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            postLog("Categories", `update-${comunidadId}`, user.fullName);
            history.push("/comunidades");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (event.target.value === " ") return;
    if (lang) {
      if (!comunidad[element]) comunidad[element] = {};
      let newText = comunidad[element];
      newText[lang] = event.target.value;
      setComunidad({ ...comunidad, [element]: newText });
    } else setComunidad({ ...comunidad, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar comunidad"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Nombre"
              value={comunidad.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={comunidad.active}
                  onChange={() =>
                    setComunidad({ ...comunidad, active: !comunidad.active })
                  }
                  name="checkActive"
                />
              }
              label="Activa"
            />
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/comunidades")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Atrás
          </Button>
          <Button
            onClick={() => saveComunidad()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Guardar comunidad
          </Button>
          {comunidadId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  Eliminar comunidad
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                ></div>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"¿Estás seguro de que quieres eliminar esta comunidad?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteComunidad(comunidadId)
                    .then((res) => {
                      if (res.status === 204 || res.status === 200) {
                        alertSuccess({
                          title: "¡Eliminada!",
                          customMessage: "Comunidad eliminada correctamente",
                        });
                        postLog(
                          "Comunidades",
                          `delete-${comunidadId}`,
                          user.fullName
                        );
                        history.push("/comunidades");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "No se ha podido eliminar la comunidad.",
                      });
                    });
                }}
              />
            </>
          )}
        </div>
      </>
    );
}
