import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { getLogs } from "../../../../api/log";
import { getUserById } from "../../../../api/user";
import replaceSpecialCharacters from "replace-special-characters";

function getData(logs) {
  let data = [];
  for (let i = 0; i < logs.length; ++i) {
    const elem = {};
    elem.user = logs[i].name;
    elem.userIP = logs[i].userIP;
    elem.action = logs[i].action;
    elem.field = logs[i].field;
    elem.date = logs[i].date;
    data = data.concat(elem);
  }
  return data;
}

export default function LogsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState("");

  useEffect(() => {
    let isMounted = true;

    const obtainLogs = async () => {
      try {
        const res = await getLogs(res);
        if (res.status === 200 && isMounted) {
          setData(getData(res.data));
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Can't get the logs",
        });
      }
    };

    obtainLogs();
    return () => {
      isMounted = false;
    };
  }, []);

  function exportToCsv(filename, rows) {
    let csvFile = "";
    csvFile += `${["USER", "IP", "ACTION", "FIELD", "DATE"].join(";")}\r\n`;

    rows.forEach((row) => {
      const properValues = [
        replaceSpecialCharacters(row.user),
        replaceSpecialCharacters(row.userIP),
        replaceSpecialCharacters(row.action),
        replaceSpecialCharacters(row.field),
        replaceSpecialCharacters(row.date),
      ];
      return (csvFile += `${properValues.join(";")}\r\n`);
    });

    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const columns = [
    { dataField: "user", text: "User", sort: true },
    { dataField: "userIP", text: "IP", sort: true },
    { dataField: "action", text: "Action", sort: true },
    { dataField: "field", text: "Field", sort: true },
    {
      dataField: "date",
      text: "Date",
      formatter: dateFormatter,
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ];

  return (
    <>
      <Card>
        <CardHeader title="Logs List">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0");
                const yyyy = today.getFullYear();
                today = dd + "-" + mm + "-" + yyyy;
                exportToCsv("Logs_" + today, data);
              }}
            >
              Export excel
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} defaultSorted={defaultSorted} />
        </CardBody>
      </Card>
    </>
  );
}
