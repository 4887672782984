import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getStoreCategories } from "../../../../api/storeCategories";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";

function getData(orders) {
  let data = [];
  for (let i = 0; i < orders.length; ++i) {
    const elem = {};
    elem.name = orders[i].name;
    elem.description =
      orders[i].description.length > 0
        ? orders[i].description
        : "No description";
    elem.numProducts = orders[i].count;
    elem.id = orders[i].id;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function StoreCategoriesPage() {
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [products, setstoreCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  const columns = [
    { dataField: "name", text: "Name", sort: true },
    { dataField: "description", text: "Description", sort: true },
    { dataField: "numProducts", text: "Number of products", sort: true },
    { dataField: "id", text: "Id", sort: true },
  ];

  useEffect(() => {
    getStoreCategories()
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setData(res.data);
          setFilteredData(res.data);
          setstoreCategories(res.data);
          setRefresh(false);
          postLog("products-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get products.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="storeCategories list"></CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />
          <Table data={getData(filteredData, products)} columns={columns} />
        </CardBody>
      </Card>
    </>
  );
}
