/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MapIcon from "@material-ui/icons/Map";
import RoomIcon from "@material-ui/icons/Room";
import LanguageIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import AssignmentIcon from "@material-ui/icons/Assignment";
import InstagramIcon from "@material-ui/icons/Instagram";
import TableChartIcon from "@material-ui/icons/TableChart";
import InfoIcon from "@material-ui/icons/Info";
import DraftsIcon from "@material-ui/icons/Drafts";
import UpdateIcon from "@material-ui/icons/Update";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LocalConvenienceStoreIcon from "@material-ui/icons/LocalConvenienceStore";
import WorkIcon from "@material-ui/icons/Work";
import PeopleIcon from "@material-ui/icons/People";

import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">USERS</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        {user?.role === "admin" && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/admins",
              false
            )} ${getMenuItemActive("/edit-admin", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admins">
              <span className="menu-icon">
                <AssignmentIndIcon />
              </span>
              <span className="menu-text">Administradores</span>
            </NavLink>
          </li>
        )}
        <li className="menu-section">
          <h4 className="menu-text">Management</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/contacts",
            false
          )} ${getMenuItemActive("/edit-contact", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/contacts">
            <span className="menu-icon">
              <ContactMailIcon />
            </span>
            <span className="menu-text">Contactos</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/newsLetters",
            false
          )} ${getMenuItemActive("/edit-newsLetters", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/newsLetters">
            <span className="menu-icon">
              <MarkunreadMailboxIcon />
            </span>
            <span className="menu-text">News Letters</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/import-data",
            false
          )} ${getMenuItemActive("/edit-import-data", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/import-data">
            <span className="menu-icon">
              <TableChartIcon />
            </span>
            <span className="menu-text">Importar datos</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/personal",
            false
          )} ${getMenuItemActive("/edit-personal", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/personal">
            <span className="menu-icon">
              <PeopleIcon />
            </span>
            <span className="menu-text">Personal</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/contracts",
            false
          )} ${getMenuItemActive("/edit-contract", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/contracts">
            <span className="menu-icon">
              <AssignmentIcon />
            </span>
            <span className="menu-text">Contratos</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">MISCELLANY</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        {user?.role === "admin" && (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/categories",
                false
              )} ${getMenuItemActive("/edit-category", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/categories">
                <span className="menu-icon">
                  <CategoryIcon />
                </span>
                <span className="menu-text">Categorías</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/charges",
                false
              )} ${getMenuItemActive("/edit-charge", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/charges">
                <span className="menu-icon">
                  <WorkIcon />
                </span>
                <span className="menu-text">Cargos</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/products",
                false
              )} ${getMenuItemActive("/edit-product", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/products">
                <span className="menu-icon">
                  <AddShoppingCartIcon />
                </span>
                <span className="menu-text">Productos</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/store-orders",
                false
              )} ${getMenuItemActive("/edit-store-order", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/store-orders">
                <span className="menu-icon">
                  <ReceiptIcon />
                </span>
                <span className="menu-text">Store Orders</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/store-categories",
                false
              )} ${getMenuItemActive("/edit-store-category", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/store-categories">
                <span className="menu-icon">
                  <LocalConvenienceStoreIcon />
                </span>
                <span className="menu-text">Store Categories</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/campaigns",
                false
              )} ${getMenuItemActive("/see-campaign", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/campaigns">
                <span className="menu-icon">
                  <DraftsIcon />
                </span>
                <span className="menu-text">Campañas</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/origins",
                false
              )} ${getMenuItemActive("/edit-origin", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/origins">
                <span className="menu-icon">
                  <InstagramIcon />
                </span>
                <span className="menu-text">Origenes</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/comunidades",
                false
              )} ${getMenuItemActive("/edit-comunidad", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/comunidades">
                <span className="menu-icon">
                  <MapIcon />
                </span>
                <span className="menu-text">Comunidades</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/provincias",
                false
              )} ${getMenuItemActive("/edit-provincia", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/provincias">
                <span className="menu-icon">
                  <RoomIcon />
                </span>
                <span className="menu-text">Provincias</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/localidades",
                false
              )} ${getMenuItemActive("/edit-localidad", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/localidades">
                <span className="menu-icon">
                  <LocationCityIcon />
                </span>
                <span className="menu-text">Localidades</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/languages",
                false
              )} ${getMenuItemActive("/edit-language", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/languages">
                <span className="menu-icon">
                  <LanguageIcon />
                </span>
                <span className="menu-text">Languages</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/logs",
                false
              )} ${getMenuItemActive("/edit-log", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/logs">
                <span className="menu-icon">
                  <InfoIcon />
                </span>
                <span className="menu-text">Logs</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/texts",
                false
              )} ${getMenuItemActive("/edit-text", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/texts">
                <span className="menu-icon">
                  <DescriptionIcon />
                </span>
                <span className="menu-text">Plantillas</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/updates",
                false
              )} ${getMenuItemActive("/edit-update", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/updates">
                <span className="menu-icon">
                  <UpdateIcon />
                </span>
                <span className="menu-text">Actualizaciones</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
