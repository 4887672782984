import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getProvincias, deleteProvincia } from "../../../../api/provincia";
import {
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getComunidades } from "../../../../api/comunidad";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(provincias, comunidades) {
  let data = [];
  for (let i = 0; i < provincias.length; ++i) {
    const elem = {};
    elem.name = provincias[i].fullName;
    elem.comunidad = provincias[i].comunidad
      ? comunidades.find((cat) => cat._id === provincias[i].comunidad)?.name
      : "---";
    elem.createdAt = provincias[i].createdAt;
    elem.id = provincias[i]._id;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  comunidad: [],
};

export default function ProvinciasPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [provinciaId, setProvinciaId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [comunidades, setComunidades] = useState([]);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const [collapsed, setCollapsed] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    let deleteButton;
    if (user?.role === "admin")
      deleteButton = (
        <Tooltip title="Eliminar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setProvinciaId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );

    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-provincia/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Provincia", sort: true },
    { dataField: "comunidad", text: "Comunidad", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getComunidades()
      .then((resp) => {
        if (resp.status === 200) {
          setComunidades(resp.data);
          setRefresh(false);
          postLog("provincias-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las comunidades.",
        });
      });
    getProvincias()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las provincias.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.comunidad && filterOptions.comunidad.length)
          filter = filter && filterOptions.comunidad.includes(item.comunidad);
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const handleChange = (element) => (event) => {
    setFilterOptions({ ...filterOptions, [element]: event.target.value });
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-standard-label">
            Comunidad
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={filterOptions.comunidad || ""}
            multiple
            onChange={handleChange("comunidad")}
            MenuProps={MenuProps}
          >
            {comunidades?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Lista de Províncias">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-provincia")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table data={getData(filteredData, comunidades)} columns={columns} />

          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta provincia?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProvincia(provinciaId)
                .then((res) => {
                  if (res.status === 204) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "Provincia eliminada correctamente.",
                    });
                    postLog("Provincias", "delete", user.fullName);
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la provincia.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
