import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

// Get all personal
export const getPersonal = () => {
  return authClient().get(`${API}/personal?getDisabled=true`);
};

// Get personal by id
export const getPersonalById = (id) => {
  return authClient().get(`${API}/personal/${id}`);
};

// Delete personal
export const deletePersonal = (id) => {
  return authClient().delete(`${API}/personal/${id}`);
};

// Create personal
export const postPersonal = async (personal) => {
  return authClient().post(`${API}/personal`, personal);
};

// Update personal
export const updatePersonal = async (id, personal) => {
  return authClient().put(`${API}/personal/${id}`, personal);
};

// Change status personal
export const changeStatusPersonal = async (id, active) => {
  return authClient().put(`${API}/personal/change-status/${id}`, { active });
};
