import { authClient, API } from "../index";

// Get all products
export const getProducts = () => {
  return authClient().get(`${API}/product?getDisabled=true`);
};

// Get product by id
export const getProductById = (id) => {
  return authClient().get(`${API}/product/${id}`);
};

// Delete product
export const deleteProduct = (id) => {
  return authClient().delete(`${API}/product/${id}`);
};

// Create product
export const postProduct = async (product) => {
  return authClient().post(`${API}/product`, product);
};

// Update product
export const updateProduct = async (id, product) => {
  return authClient().put(`${API}/product/${id}`, product);
};

// Count all products
export const countProducts = () => {
  const role = "product";
  return authClient().get(`${API}/product/count/${role}`);
};

// Change status product
export const changeStatusProduct = async (id, active) => {
  return authClient().put(`${API}/product/change-status/${id}`, { active });
};
