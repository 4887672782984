import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getContractById, updateContract } from "../../../../api/contract";
import { alertError, alertSuccess } from "../../../../utils/logger";
import SignaturePad from "react-signature-canvas";
import { Button, Container, Typography, Box } from "@material-ui/core";
import { login } from "../../../../api/auth";
import { uploadSingleFile } from "../../../../api/files";

export default function SignContractPage() {
  const { id } = useParams();
  const [contract, setContract] = useState(null);
  const [signaturePad, setSignaturePad] = useState(null);
  const [successfully, setSuccessfully] = useState(false);

  useEffect(() => {
    getContractById(id)
      .then((res) => {
        if (res.status === 200) {
          setContract(res.data);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get contract." });
      });

    login("jnavarro@owius.com", "Jn.Owius").then((res) => {
      if (res.status === 200) {
        console.log(res.data);
      }
    });
  }, [id]);

  const handleSave = async () => {
    if (!signaturePad.isEmpty()) {
      const signature = signaturePad.toDataURL();
      const file = await fetch(signature)
        .then((res) => res.blob())
        .then(
          (blob) => new File([blob], "signature.png", { type: "image/png" })
        );

      try {
        const res = await uploadSingleFile(file, "signatures");
        const signatureUrl = res.data.fileURL;

        updateContract(id, { clientSign: signatureUrl })
          .then((res) => {
            if (res.status === 200) {
              alertSuccess({
                title: "Signed!",
                customMessage: "Contract signed successfully.",
              });
              setSuccessfully(true);
            }
          })
          .catch((error) => {
            alertError({
              error: error,
              customMessage: "Could not save signature.",
            });
          });
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not upload signature.",
        });
      }
    } else {
      alertError({ customMessage: "Please provide a signature." });
    }
  };

  const handleClear = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  return (
    <Container maxWidth="sm">
      {contract && !successfully && (
        <>
          <Box textAlign="center" my={4}>
            <Typography variant="h4" gutterBottom>
              FIRMA EL SIGUIENTE CONTRATO
            </Typography>
            <Typography variant="h6">
              {`Contrato para ${contract.fullName}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            border={1}
            borderColor="grey.500"
            borderRadius={8}
            p={2}
            mb={4}
            bgcolor="background.paper"
          >
            <SignaturePad
              ref={(ref) => setSignaturePad(ref)}
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            />
          </Box>
          <Box textAlign="center">
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              size="large"
              style={{ marginRight: "10px" }}
            >
              Save Signature
            </Button>
            <Button
              onClick={handleClear}
              variant="outlined"
              color="secondary"
              size="large"
            >
              Clear Signature
            </Button>
          </Box>
        </>
      )}
      {successfully && (
        <Box textAlign="center" my={4}>
          <Typography variant="h4" gutterBottom>
            ¡Firma guardada con éxito!
          </Typography>
          <Typography variant="h6">
            {`Contrato para ${contract.fullName}`}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
