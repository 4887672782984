import { API, authClient } from "../index";

// Get all charges
export const getCharges = () => {
  return authClient().get(`${API}/charge?getDisabled=true`);
};

// Get charge by id
export const getChargeById = (id) => {
  return authClient().get(`${API}/charge/${id}`);
};

// Delete charge
export const deleteCharge = (id) => {
  return authClient().delete(`${API}/charge/${id}`);
};

// Create charge
export const postCharge = async (charge) => {
  return authClient().post(`${API}/charge`, charge);
};

// Update charge
export const updateCharge = async (id, charge) => {
  return authClient().put(`${API}/charge/${id}`, charge);
};

// Change status charge
export const changeStatusCharge = async (id, active) => {
  return authClient().put(`${API}/charge/change-status/${id}`, { active });
};
