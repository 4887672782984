import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { getBrevoCampaigns } from "../../../../api/campaign";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

function getEmptyCampaign() {
  return {
    title: "",
    description: "",
    password: "",
    photo: "",
    clients: [],
    active: true,
  };
}

export default function EditCampaignsPage() {
  const [campaign, setCampaign] = useState(getEmptyCampaign());
  const campaignId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!campaignId) {
      disableLoadingData();
      return;
    }
    getBrevoCampaigns()
      .then((response) => {
        const campaigns = response.data.email.campaigns;
        const campaign = campaigns?.find(
          (campaign) => campaign.id == campaignId
        );
        if (!campaign) {
          alertError({
            error: "Campaign not found.",
            customMessage: "Could not get campaign.",
          });
          history.push("/campaigns");
        }
        setCampaign(campaign);
        disableLoadingData();
      })
      .catch((error) => {
        console.log("ENTRA AQUI", error);
        alertError({
          error: error,
          customMessage: "Could not get campaign",
        });
        history.push("/campaigns");
      });
    console.log("campaignId", campaignId);
  }, [campaignId, disableLoadingData, history]);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const hours = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0");
    const seconds = date
      .getSeconds()
      .toString()
      .padStart(2, "0");

    return `${day}-${month}-${year} - ${hours}:${minutes}:${seconds} h`;
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Datos Campaña"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Nombre"
              value={campaign.name}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              disabled
            />
            <TextField
              id={`status`}
              label="Estado"
              value={campaign.status}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              disabled
            />
            <TextField
              id={`createdAt`}
              label="Creado el"
              value={formatDate(campaign.createdAt)}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              disabled
            />
            <TextField
              id={`scheduledAt`}
              label="Programado para"
              value={formatDate(campaign.scheduledAt)}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              disabled
            />
            <br />
            <div>
              <h3>Estadísticas de la campaña</h3>
              <TextField
                id={`listId`}
                label="ID de la lista"
                value={campaign.statistics?.campaignStats[0]?.listId || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`uniqueClicks`}
                label="Clicks únicos"
                value={
                  campaign.statistics?.campaignStats[0]?.uniqueClicks || ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`clickers`}
                label="Clickers"
                value={campaign.statistics?.campaignStats[0]?.clickers || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`complaints`}
                label="Quejas"
                value={campaign.statistics?.campaignStats[0]?.complaints || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`delivered`}
                label="Entregados"
                value={campaign.statistics?.campaignStats[0]?.delivered || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`sent`}
                label="Enviados"
                value={campaign.statistics?.campaignStats[0]?.sent || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`uniqueViews`}
                label="Vistas únicas"
                value={campaign.statistics?.campaignStats[0]?.uniqueViews || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`trackableViews`}
                label="Vistas rastreables"
                value={
                  campaign.statistics?.campaignStats[0]?.trackableViews || ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`unsubscriptions`}
                label="Bajas"
                value={
                  campaign.statistics?.campaignStats[0]?.unsubscriptions || ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
              <TextField
                id={`viewed`}
                label="Visto por:"
                value={
                  campaign.statistics?.campaignStats[0]?.viewed +
                    " persona/s" || ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                disabled
              />
            </div>
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/campaigns")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
      </>
    );
}
