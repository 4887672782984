import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import TableDialog from "../../../components/dialogs/TableDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { Visibility } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { buttonsStyle } from "../../../components/tables/table";
import Editor from "../../../components/editor/Editor";
import { getNews } from "../../../../api/new";
import { SERVER_URL } from "../../../../api/index";
import { getContacts } from "../../../../api/contact";
import { getLocalidades } from "../../../../api/localidad";
import {
  deleteNewsLetter,
  getNewsLetterById,
  postNewsLetter,
  updateNewsLetter,
  sendNewsLetter,
  getClientsSegments,
} from "../../../../api/newsLetter";
import { postLog } from "../../../../api/log";
import { getCategories } from "../../../../api/category";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyNewsLetter() {
  return {
    title: "",
    description: "",
    image: "",
    news: [],
    active: true,
    clients: {
      segments: [],
      lists: [],
      users: [],
    },
    sended: false,
  };
}

const getData = (news) => {
  let data = [];
  console.log(news[0]);
  for (let i = 0; i < news.length; i++) {
    const elem = {};
    elem.id = news[i].id;
    elem.date = news[i].date;
    elem.title = news[i].title.rendered;
    elem.content = news[i].content.rendered;
    elem.link = news[i].link;
    data = data.concat(elem);
  }
  return data;
};

export default function EditNewsLettersPage() {
  const [newsLetter, setNewsLetter] = useState(getEmptyNewsLetter());
  const [subNewsLetters, setSubNewsLetters] = useState([]);
  const [openTableDialog, setOpenTableDialog] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [news, setNews] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [segments, setSegments] = useState({ segments: [], lists: [] });
  const newsLetterId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  const groupSubcategories = (sub) => {
    const groupedSubcategories = sub.reduce((result, subcategory) => {
      const { key, value } = subcategory;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
      return result;
    }, {});
    return groupedSubcategories;
  };

  useEffect(() => {
    getNews().then((resp) => {
      if (resp.status === 200) {
        console.log(resp.data);
        setNews(getData(resp.data));
        disableLoadingData();
      }
    });

    getClientsSegments().then((res) => {
      if (res.status === 200) {
        setSegments(res.data);
      }
    });

    getContacts().then((res) => {
      if (res.status === 200) {
        const contacts = res.data.map((c) =>
          c.fullName === "undefined undefined"
            ? { ...c, fullName: "---------" }
            : c
        );
        setContacts(contacts);
      }
    });

    getLocalidades().then((res) => {
      if (res.status === 200) {
        setLocalidades(res.data);
      }
    });

    getCategories().then((res) => {
      if (res.status === 200) {
        setCategories(res.data);
        let subcategories = [];
        res.data.forEach((category) => {
          category.subcategories.forEach((subcategory) => {
            subcategories.push({
              key: subcategory.key,
              value: subcategory.value,
            });
          });
        });
        setSubcategories(groupSubcategories(subcategories));
      }
    });
    disableLoadingData();

    if (!newsLetterId) {
      return;
    }

    getNewsLetterById(newsLetterId)
      .then((res) => {
        if (res.status === 200) {
          const newsLetter = res.data;
          setNewsLetter(newsLetter);
          // setSelectedImage(newsLetter?.image);
          setSubNewsLetters(newsLetter.news);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get newsLetter.",
        });
        history.push("/newsLetters");
      });
  }, [newsLetterId, disableLoadingData, history]);

  async function saveNewsLetter(message = "") {
    newsLetter.news = subNewsLetters;
    let saveNewsLetter = newsLetter;
    if (!newsLetterId) {
      postNewsLetter(saveNewsLetter, selectedImage)
        .then((res) => {
          if (res.status === 201) {
            postLog("NewsLetter", `create-${newsLetterId}`, user.fullName);
            if (message === "send") return;
            alertSuccess({
              title: "Saved!",
              customMessage: "NewsLetter successfully created.",
            });
            history.push("/newsLetters");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save newsLetter.",
          });
        });
    } else {
      updateNewsLetter(newsLetterId, saveNewsLetter, selectedImage)
        .then((res) => {
          if (res.status === 200) {
            postLog("NewsLetter", `update-${newsLetterId}`, user.fullName);
            if (message === "send") return;
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/newsLetters");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setNewsLetter({ ...newsLetter, [element]: event.target.value });
  };

  const handleChangeEditor = (element, value) => {
    if (value === " ") return;
    else setNewsLetter({ ...newsLetter, [element]: value });
  };

  const handleAddNews = () => {
    setSubNewsLetters([
      ...subNewsLetters,
      { id: null, title: "", date: "", content: "", link: "" },
    ]);
  };

  const handleRemoveNews = (index) => {
    const updatedSubNewsLetters = subNewsLetters.filter((_, i) => i !== index);
    setSubNewsLetters(updatedSubNewsLetters);
  };

  const renderDynamicNewsSelections = () => {
    return (
      <div>
        {newsLetterId && (
          <section>
            <strong>
              Espere un segundo para ver las noticias seleccionadas, a veces
              puede tardar un poco. &nbsp; En caso que no aparezcan, por favor
              recargue la página.
            </strong>
            <br />
            <br />
          </section>
        )}
        {subNewsLetters.map((item, index) => (
          <div
            key={index}
            style={{ marginBottom: 10 }}
            className="d-flex flex-row"
          >
            <Autocomplete
              id={`news-autocomplete-${index}`}
              options={news}
              getOptionLabel={(option) => option.title || ""}
              value={news.find((n) => n.id === item.id) || null}
              onChange={(event, newValue) => {
                const updatedItems = subNewsLetters.map((subItem, subIndex) =>
                  subIndex === index
                    ? {
                        ...subItem,
                        id: newValue?.id,
                        title: newValue?.title,
                        date: newValue?.date,
                        content: newValue?.content,
                        link: newValue?.link,
                      }
                    : subItem
                );
                setSubNewsLetters(updatedItems);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select News" variant="outlined" />
              )}
              style={{ width: "50%", marginBottom: "10px" }}
            />
            <IconButton onClick={() => handleRemoveNews(index)} size="small">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
    );
  };

  const handleSendNewsLetter = () => {
    sendNewsLetter(newsLetter)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "Sent!",
            customMessage: "NewsLetter sent successfully.",
          });
          postLog("NewsLetter", `send-${newsLetter}`, user.fullName);
          history.push("/newsLetters");
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not send newsLetter.",
        });
      });
  };

  const saveUsers = (users) => {
    setNewsLetter({
      ...newsLetter,
      clients: { ...newsLetter.clients, users: [...users] },
    });
  };

  useEffect(() => {
    console.log(newsLetter);
  }, [newsLetter]);

  useEffect(() => {
    console.log("CONTACTS: ", contacts);
  }, [contacts]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit newsLetter"></CardHeader>
          <CardBody>
            <TextField
              id={`título`}
              label="Título"
              value={newsLetter.title}
              onChange={handleChange("title")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <Editor
              body={newsLetter.description || ""}
              setBody={(new_body) =>
                handleChangeEditor("description", new_body)
              }
              className="max-height"
              placeholder={"Enter NesLetter description here"}
              label="Description"
            />
            <br />
            <label htmlFor={"upload-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-image"}
                name={"upload-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = originalFile.name;
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setSelectedImage(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedImage || (newsLetter.image && newsLetter.image !== "")
                  ? "Change multimedia file"
                  : "Upload multimedia file"}
              </Button>
            </label>
            {(selectedImage ||
              (newsLetter.image && newsLetter.image !== "")) && (
              <>
                <span>
                  {selectedImage
                    ? selectedImage?.name
                    : newsLetter.image && newsLetter.image !== ""
                    ? newsLetter.image?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialog(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview image"}
                  open={openPreviewDialog}
                  setOpen={setOpenPreviewDialog}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : `${SERVER_URL}/${newsLetter.image}`
                  }
                />
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (selectedImage) setSelectedImage(null);
                      else setNewsLetter({ ...newsLetter, image: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            <span>
              Envía newsLetter a una lista o segmento de usuarios creados en el
              Brevo:
            </span>
            <section className="d-flex justify-content-around my-5">
              {!newsLetter?.clients?.users?.length ? (
                <>
                  <Autocomplete
                    multiple
                    id="lists-standard"
                    options={segments.lists}
                    getOptionLabel={(option) => option.name}
                    value={
                      newsLetter?.clients?.lists ? newsLetter.clients.lists : []
                    }
                    onChange={(event, newValue) => {
                      setNewsLetter({
                        ...newsLetter,
                        clients: {
                          ...newsLetter.clients,
                          lists: newValue,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Lists"
                        placeholder="Lists"
                      />
                    )}
                    className="w-25 mr-2"
                  />
                  <br />
                  <br />
                  <Autocomplete
                    multiple
                    id="segments-standard"
                    options={segments.segments}
                    getOptionLabel={(option) => option.segmentName}
                    value={
                      newsLetter?.clients?.segments
                        ? newsLetter.clients.segments
                        : []
                    }
                    onChange={(event, newValue) => {
                      setNewsLetter({
                        ...newsLetter,
                        clients: {
                          ...newsLetter.clients,
                          segments: newValue,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Segments"
                        placeholder="Segments"
                      />
                    )}
                    className="w-25"
                  />
                </>
              ) : (
                <b>
                  Se ha desabilitado la selección por segmentos o listas ya que
                  se ha preferido hacer una selección por usuarios.
                </b>
              )}
            </section>
            <span>
              O filtra los usuarios a los que quieres enviar el newsLetter:
            </span>
            <br />
            <section className="mt-5">
              {!newsLetter?.clients?.lists?.length &&
              !newsLetter?.clients?.segments?.length ? (
                <>
                  <Button
                    onClick={() => setOpenTableDialog(true)}
                    variant="outlined"
                    color="primary"
                  >
                    Add new User's selection
                  </Button>
                  <TableDialog
                    open={openTableDialog}
                    setOpen={setOpenTableDialog}
                    data={contacts}
                    onSelectRow={(id) => {}}
                    title={"Select Users"}
                    localidades={localidades}
                    categorias={categories}
                    subcategorias={subcategories}
                    saveUsers={saveUsers}
                  />
                  {newsLetter.clients.users.length > 0 && (
                    <span className="m-2 p-3">
                      Total usuarios seleccionados:{" "}
                      {newsLetter.clients.users.length}
                    </span>
                  )}
                </>
              ) : (
                <b className="p-3">
                  Se ha desabilitado la selección por usuario ya que se ha
                  preferido enviar a una lista o segmento de usuarios.
                </b>
              )}
            </section>
            <br />
            <br />
            {renderDynamicNewsSelections()}
            <Button
              onClick={handleAddNews}
              variant="outlined"
              color="primary"
              style={{ margin: "10px 0" }}
            >
              Add another new
            </Button>
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsLetter.active}
                  onChange={() =>
                    setNewsLetter({ ...newsLetter, active: !newsLetter.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/newsLetters")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveNewsLetter()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save newsLetter
        </Button>
        <Button
          onClick={async () => {
            if (
              window.confirm(
                "Are you sure you want to send this NewsLetter to clients?"
              )
            ) {
              try {
                await saveNewsLetter("send");
                handleSendNewsLetter();
              } catch (error) {
                console.error("Error saving NewsLetter:", error);
              }
            }
          }}
          style={{ marginRight: "20px" }}
          variant="outlined"
          color="primary"
        >
          Save and Send
        </Button>
        {newsLetterId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete newsLetter
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this newsLetter?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteNewsLetter(newsLetterId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "NewsLetter deleted successfully",
                      });
                      postLog(
                        "NewsLetter",
                        `delete-${newsLetterId}`,
                        user.fullName
                      );
                      history.push("/newsLetters");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete newsLetter.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
