import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getStoreOrders } from "../../../../api/storeOrders";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";

function getData(orders) {
  let data = [];
  for (let i = 0; i < orders.length; ++i) {
    const elem = {};
    elem.email = orders[i].billing.email;
    elem.total = orders[i].total.concat(`${orders[i].currency_symbol}`);
    elem.payment_method_title = orders[i].payment_method_title;
    elem.active = orders[i].status;
    elem.orderNumber = orders[i].number;
    elem.productsnum = orders[i].line_items.length;
    elem.products = orders[i].line_items;
    elem.createdAt = orders[i].date_created;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function StoreOrdersPage() {
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [products, setStoreOrders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  const columns = [
    { dataField: "email", text: "Email", sort: true },
    { dataField: "total", text: "Total", sort: true },
    { dataField: "payment_method_title", text: "Tipo de pago", sort: true },
    { dataField: "active", text: "Estado", sort: true },
    { dataField: "orderNumber", text: "Número de pedido", sort: true },
    { dataField: "productsnum", text: "Número de productos", sort: true },
    {
      dataField: "products",
      text: "Productos",
      formatter: (cell, row) => {
        return (
          <>
            {row.products.map((product, index) => (
              <Tooltip key={index} title={product.name}>
                <span>{product.name.substring(0, 50)}</span>
              </Tooltip>
            ))}
          </>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Fecha del pedido",
      formatter: dateFormatter,
      sort: true,
    },
  ];

  useEffect(() => {
    getStoreOrders()
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setData(res.data);
          setFilteredData(res.data);
          setStoreOrders(res.data);
          setRefresh(false);
          postLog("products-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get products.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="StoreOrders list"></CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />
          <Table data={getData(filteredData, products)} columns={columns} />
        </CardBody>
      </Card>
    </>
  );
}
