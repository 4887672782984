import { authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all newsLetters
export const getNewsLetters = () => {
  return authClient().get(`${API}/newsLetter?getDisabled=true`);
};

// Get newsLetter by id
export const getNewsLetterById = (id) => {
  return authClient().get(`${API}/newsLetter/${id}`);
};

// Delete newsLetter
export const deleteNewsLetter = (id) => {
  return authClient().delete(`${API}/newsLetter/${id}`);
};

// Create newsLetter
export const postNewsLetter = async (newsLetter, image) => {
  if (image) {
    const res = await uploadSingleFile(image, "newsLetter");
    newsLetter.image = res.data.fileURL;
  }
  return authClient().post(`${API}/newsLetter`, newsLetter);
};

//send newsLetter
export const sendNewsLetter = async (newsLetter) => {
  return authClient().post(`${API}/newsLetter/send`, newsLetter);
};

// Update newsLetter
export const updateNewsLetter = async (id, newsLetter, image) => {
  if (image) {
    const res = await uploadSingleFile(image, "newsLetter");
    newsLetter.image = res.data.fileURL;
  }
  return authClient().put(`${API}/newsLetter/${id}`, newsLetter);
};

// Count all admins
export const countAdmins = () => {
  const role = "admin";
  return authClient().get(`${API}/newsLetter/count/${role}`);
};

// Change status newsLetter
export const changeStatusNewsLetter = async (id, active) => {
  return authClient().put(`${API}/newsLetter/change-status/${id}`, { active });
};

// Get Clients Segments
export const getClientsSegments = () => {
  return authClient().get(`${API}/newsLetter/segments`);
};
