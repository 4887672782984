import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getContacts,
  deleteContact,
  changeStatusContact,
} from "../../../../api/contact";
import {
  Button,
  Tooltip,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";
import { getNonEmpty } from "../../../../utils/helpers";
import { getCategories } from "../../../../api/category";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(contacts) {
  let data = [];
  for (let i = 0; i < contacts.length; ++i) {
    const elem = {};
    elem.fullName =
      contacts[i].fullName === "undefined undefined"
        ? "---------"
        : contacts[i].fullName;
    elem.email = contacts[i].email;
    elem.contactType = contacts[i].contactType;
    elem.createdAt = contacts[i].createdAt;
    elem.telMob = contacts[i].telMob;
    elem.telFij = contacts[i].telFij;
    elem.id = contacts[i]._id;
    elem.active = contacts[i].active;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
  category: [],
  subcategory: [],
};

export default function ContactsPage() {
  const [data, setData] = useState([]);
  const [contactId, setContactId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-contact/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setContactId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setContactId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const groupSubcategories = (sub) => {
    const groupedSubcategories = sub.reduce((result, subcategory) => {
      const { key, value } = subcategory;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
      return result;
    }, {});
    return groupedSubcategories;
  };

  const columns = [
    { dataField: "fullName", text: "Full Name", sort: true },
    { dataField: "email", text: "Email", sort: true },
    { dataField: "telMob", text: "Mobile phone", sort: true },
    { dataField: "telFij", text: "Fixed phone", sort: true },
    { dataField: "contactType", text: "Contact type", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getContacts()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
          setContacts(res.data);
          setRefresh(false);
          postLog("contacts-list", "visualize", user.fullName);
          getCategories().then((res) => {
            if (res.status === 200) {
              setCategories(res.data);
              //each category contains a subcategory array
              let subcategories = [];
              res.data.forEach((category) => {
                category.subcategories.forEach((subcategory) => {
                  subcategories.push({
                    key: subcategory.key,
                    value: subcategory.value,
                  });
                });
              });
              setSubcategories(groupSubcategories(subcategories));
            }
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get contacts.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        console.log("ACTIVE: ", filter);
        if (filterOptions.category && filterOptions.category.length)
          filter =
            filter &&
            filterOptions.category.some((category) =>
              item.category.includes(category)
            );
        console.log("Category: ", filter);
        if (filterOptions.subcategory && filterOptions.subcategory.length) {
          filter =
            filter &&
            filterOptions.subcategory.every((subcategory) => {
              const subcategoryMatch = item.subcategory.find((sub) =>
                sub.values.some((value) => {
                  if (Array.isArray(value.value)) {
                    return value.value.some((val) =>
                      subcategory.values.includes(val)
                    );
                  } else if (typeof subcategory.values === "string") {
                    return subcategory.values === value.value;
                  }
                })
              );

              return subcategoryMatch !== undefined;
            });
          console.log("Subcategory: ", filter);
        }
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const handleChange = (element, key) => (event) => {
    if (element === "category") {
      console.log("CATEGORY: ", event.target.value);
      let subcategories = [];
      let newFilterOptionsSubcategory = [];
      event.target.value.forEach((categoryId) => {
        const category = categories.find(
          (category) => category._id === categoryId
        );
        category.subcategories.forEach((subcategory) => {
          subcategories.push({
            key: subcategory.key,
            value: subcategory.value,
          });
          const existingFilter = filterOptions.subcategory.find(
            (f) => f.key === subcategory.key
          );
          if (existingFilter) {
            newFilterOptionsSubcategory.push(existingFilter);
          }
        });
      });
      setSubcategories(groupSubcategories(subcategories));
      setFilterOptions((prev) => ({
        ...prev,
        category: event.target.value,
        subcategory: newFilterOptionsSubcategory,
      }));
    }
    if (element === "subcategory") {
      console.log("SUBCATEGORY: ", event.target.value);
      let subcats = [...(filterOptions.subcategory || [])];
      if (key) {
        const existingSubcategory = subcats.find(
          (subcategory) => subcategory.key === key
        );
        if (existingSubcategory) {
          existingSubcategory.values = event.target.value;
        } else {
          subcats.push({
            key: key,
            values: event.target.value,
          });
        }
        setFilterOptions({
          ...filterOptions,
          [element]: subcats,
        });
        return;
      }
      const name = event.target.name;
      const selectedOptions = event.target.value;
      const index = subcats.findIndex((subcat) => subcat.key === name);
      if (index > -1) {
        subcats[index].values = selectedOptions;
      } else {
        subcats.push({ key: name, values: selectedOptions });
      }
      setFilterOptions({
        ...filterOptions,
        [element]: subcats,
      });
      return;
    }
    setFilterOptions({ ...filterOptions, [element]: event.target.value });
  };

  const renderFiltersContent = () => {
    return (
      <>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={filterOptions.category || []}
            multiple
            onChange={handleChange("category")}
            MenuProps={MenuProps}
          >
            {categories?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        {/* <InputLabel id="subcategory-section-label">Subcategory</InputLabel> */}
        {user.role === "admin" && (
          <>
            {filterOptions.category.length > 0 &&
              Object.entries(subcategories).map(([key, value]) => (
                <FormControl style={{ width: "100%" }} key={key}>
                  {value != "" ? (
                    <>
                      <InputLabel id={`${key}-select-label`}>{key}</InputLabel>
                      <Select
                        labelId={`${key}-select-label`}
                        id={`${key}-select`}
                        value={
                          filterOptions.subcategory.find(
                            (subcat) => subcat.key === key
                          )?.values || []
                        }
                        multiple
                        onChange={handleChange("subcategory")}
                        MenuProps={MenuProps}
                        name={key}
                      >
                        {value.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      id={`${key}-text-field`}
                      label={`${key}`}
                      value={
                        filterOptions.subcategory.find(
                          (subcat) => subcat.key === key
                        )?.values || ""
                      }
                      onChange={handleChange("subcategory", key)}
                      margin="normal"
                    />
                  )}
                </FormControl>
              ))}
          </>
        )}
        <br />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  useEffect(() => {
    console.log("Filter options: ", filterOptions);
  }, [filterOptions]);

  useEffect(() => {
    console.log(subcategories);
  }, [subcategories]);

  useEffect(() => {
    console.log("DATA: ", getData(contacts));
  }, [contacts]);

  return (
    <>
      <Card>
        <CardHeader title="Contacts list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-contact")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table
            data={getData(filteredData, contacts)}
            columns={columns}
            origin={"contacts"}
          />

          <ConfirmDialog
            title={`Are you sure you want to ${
              contactId?.active ? "disable" : "enable"
            } this contact?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusContact(contactId.id, !contactId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    postLog("contacts-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${contactId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Contact ${
                        contactId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      contactId?.active ? "disable" : "enable"
                    } contact.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this contact?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteContact(contactId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Contact removed successfully.",
                    });
                    setRefresh(true);
                    postLog("Contacts", "delete", user.fullName);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove contact.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
