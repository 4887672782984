import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteContract,
  getContractById,
  postContract,
  updateContract,
  getContractPdf,
} from "../../../../api/contract";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";
import QRCode from "react-qr-code";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyContract() {
  return {
    fullName: "",
    NIF: "",
    birthDate: new Date().toISOString().split("T")[0],
    homeAddress: "",
    city: "",
    postalCode: "",
    phone1: "",
    phone2: "",
    email: "",
    animalRace: "",
    animalSex: "",
    animalBirthDate: new Date().toISOString().split("T")[0],
    animalColor: "",
    animalChip: "",
    animalName: "",
    paymentMethod: "",
    clientNumber: "",
    LOE: "",
    aquanaturaCardAuth: false,
    aquanaturaCardNumber: "",
    howDidYouKnowUs: "",
    clientSign: "",
  };
}

export default function EditContractsPage() {
  const [contract, setContract] = useState(getEmptyContract());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const contractId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!contractId) {
      disableLoadingData();
      return;
    }

    getContractById(contractId)
      .then((res) => {
        if (res.status === 200) {
          const contract = res.data;
          setContract(contract);
          disableLoadingData();
          setQrCode(`${window.location.origin}/sign-contract/${contract._id}`);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get contract." });
        history.push("/contracts");
      });
  }, [contractId, disableLoadingData, history]);

  function saveContract() {
    let saveContract = contract;
    if (!contractId) {
      postContract(saveContract)
        .then((res) => {
          if (res.status === 201) {
            const newContract = res.data;
            setQrCode(
              `${window.location.origin}/sign-contract/${newContract._id}`
            );
            alertSuccess({
              title: "Saved!",
              customMessage:
                "Contract successfully created. Scan the QR code to sign.",
            });
            postLog("Contracts", `create-${newContract._id}`, user.fullName);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save contract.",
          });
        });
    } else {
      updateContract(contractId, saveContract)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Contracts", `update-${contractId}`, user.fullName);
            history.push("/contracts");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setContract({ ...contract, [element]: event.target.value });
  };

  const handleDateChange = (element) => (event) => {
    const value = event.target.value;
    if (isNaN(new Date(value))) {
      console.error("Invalid date value");
      return;
    }
    setContract({ ...contract, [element]: value });
  };

  const downloadPDF = async () => {
    try {
      const response = await getContractPdf(contractId);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `contract_${contractId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not download the PDF.",
      });
    }
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit contract"></CardHeader>
          <CardBody>
            <form noValidate autoComplete="off">
              <TextField
                label="Full Name"
                value={contract.fullName}
                onChange={handleChange("fullName")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="NIF"
                value={contract.NIF}
                onChange={handleChange("NIF")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Birth Date"
                type="date"
                value={
                  contract?.birthDate
                    ? new Date(contract?.birthDate).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleDateChange("birthDate")}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Home Address"
                value={contract.homeAddress}
                onChange={handleChange("homeAddress")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="City"
                value={contract.city}
                onChange={handleChange("city")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Postal Code"
                value={contract.postalCode}
                onChange={handleChange("postalCode")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone 1"
                value={contract.phone1}
                onChange={handleChange("phone1")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone 2"
                value={contract.phone2}
                onChange={handleChange("phone2")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={contract.email}
                onChange={handleChange("email")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Animal Race"
                value={contract.animalRace}
                onChange={handleChange("animalRace")}
                required
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Animal Sex</InputLabel>
                <Select
                  value={contract.animalSex}
                  onChange={handleChange("animalSex")}
                  required
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Animal Birth Date"
                type="date"
                value={
                  contract?.animalBirthDate
                    ? new Date(contract.animalBirthDate)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={handleDateChange("animalBirthDate")}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Animal Color"
                value={contract.animalColor}
                onChange={handleChange("animalColor")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Animal Chip"
                value={contract.animalChip}
                onChange={handleChange("animalChip")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Animal Name"
                value={contract.animalName}
                onChange={handleChange("animalName")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Payment Method"
                value={contract.paymentMethod}
                onChange={handleChange("paymentMethod")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Client Number"
                value={contract.clientNumber}
                onChange={handleChange("clientNumber")}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label="LOE"
                value={contract.LOE}
                onChange={handleChange("LOE")}
                required
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Aquanatura Card Auth</InputLabel>
                <Select
                  value={contract.aquanaturaCardAuth}
                  onChange={handleChange("aquanaturaCardAuth")}
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Aquanatura Card Number"
                value={contract.aquanaturaCardNumber}
                onChange={handleChange("aquanaturaCardNumber")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="How Did You Know Us"
                value={contract.howDidYouKnowUs}
                onChange={handleChange("howDidYouKnowUs")}
                fullWidth
                margin="normal"
              />
            </form>
            {qrCode && (
              <div style={{ marginTop: "20px" }}>
                <QRCode value={qrCode} />
                <br />
                <br />
                <strong>Escanea el QR para firmar el contrato.</strong>
              </div>
            )}
            <br />
            <br />
            {contract.clientSign && (
              <>
                <strong>Generar el PDF del contrato: </strong>
                <br />
                <br />
                <Button
                  onClick={downloadPDF}
                  variant="outlined"
                  color="primary"
                >
                  Descargar PDF y enviar por email
                </Button>
              </>
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/contracts")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveContract()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save contract
        </Button>
        {contractId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete contract
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this contract?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteContract(contractId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Contract deleted successfully",
                      });
                      postLog(
                        "Contracts",
                        `delete-${contractId}`,
                        user.fullName
                      );
                      history.push("/contracts");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete contract.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
