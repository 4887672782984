import { authClient, API } from "../index";

// Get all news
export const getNews = () => {
  return authClient().get(`${API}/new?getDisabled=true`);
};

// Get new by id
export const getNewById = (id) => {
  return authClient().get(`${API}/new/${id}`);
};

// Delete new
export const deleteNew = (id) => {
  return authClient().delete(`${API}/new/${id}`);
};

// Create new
export const postNew = async (new_) => {
  return authClient().post(`${API}/new`, new_);
};

// Update new
export const updateNew = async (id, new_) => {
  return authClient().put(`${API}/new/${id}`, new_);
};

// // Count all admins
// export const countAdmins = () => {
//   const role = "admin"
// 	return authClient().get(`${API}/new/count/${role}`)
// }

// Change status new
export const changeStatusNew = async (id, active) => {
  return authClient().put(`${API}/new/change-status/${id}`, { active });
};
