import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Grid,
  Switch,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteContact,
  getContactById,
  postContact,
  updateContact,
} from "../../../../api/contact";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess, alertInfo } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getLocalidades } from "../../../../api/localidad";
import { getUsers } from "../../../../api/user";
import { getOrigins } from "../../../../api/origin";
import { getCategories } from "../../../../api/category";
import { getClientsSegments } from "../../../../api/newsLetter";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyContact() {
  return {
    fullName: "",
    telFij: "",
    telMob: "",
    email: "",
    fechaNacimiento: new Date(),
    fechaContacto: new Date(),
    contactType: "",
    category: [],
    subcategory: [],
    contactedBy: "",
    nreferencia: "",
    origin: "",
    observations: "",
    address: "",
    localidad: "",
    wantsInfo: true,
    listIds: [],
    segmentIds: [],
    active: true,
  };
}

export default function EditContactsPage() {
  const [contact, setContact] = useState(getEmptyContact());
  const [localidades, setLocalidades] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [segments, setSegments] = useState({ segments: [], lists: [] });
  const user = useSelector((store) => store.authentication?.user);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [interestFields, setInterestFields] = useState([
    { categoryId: "", subcategories: [] },
  ]);

  const [users, setUsers] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const contactId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!contactId) {
      disableLoadingData();
      return;
    }

    getContactById(contactId)
      .then((res) => {
        if (res.status === 200) {
          const contact = res.data;
          setContact(contact);
          disableLoadingData();

          const adaptedSubcategories = contact.subcategory.map(
            (subcategory) => ({
              categoryId: subcategory.cat,
              subcategories: subcategory.values,
            })
          );
          const adaptedSubcategories2 = contact.subcategory.map(
            (subcategory) => ({
              cat: subcategory.cat,
              values: subcategory.values,
            })
          );
          setSubcategories(adaptedSubcategories2);
          setInterestFields(adaptedSubcategories);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get contact." });
        history.push("/admins");
      });
  }, [contactId, disableLoadingData, history]);

  useEffect(() => {
    getLocalidades()
      .then((res) => {
        if (res.status === 200) {
          setLocalidades(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get localidades.",
        });
        history.push("/contacts");
      });

    getClientsSegments()
      .then((res) => {
        if (res.status === 200) {
          setSegments(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get segments.",
        });
      });

    getUsers()
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          setUsers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get users.",
        });
        history.push("/contacts");
      });

    getOrigins()
      .then((res) => {
        if (res.status === 200) {
          setOrigins(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get origins.",
        });
        history.push("/contacts");
      });

    getCategories()
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data);
          const subcategoriesArray = res.data.flatMap((category) =>
            Object.entries(category.subcategories)
          );
          const subcategories = subcategoriesArray.map(
            ([, subcategory]) => subcategory
          );
          // console.log(subcategories);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get categories.",
        });
        history.push("/contacts");
      });
  }, []);

  function saveContact() {
    let saveContact = contact;
    if (!contactId) {
      postContact(saveContact)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Contact successfully created.",
            });
            postLog("Contacts", `create-${contactId}`, user.fullName);
            history.push("/contacts");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save contact.",
          });
        });
    } else {
      updateContact(contactId, saveContact)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Contacts", `update-${contactId}`, user.fullName);
            history.push("/contacts");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const updateSubcategoryData = (key, selectedValues, index) => {
    console.log("UPDATE_SUBCATEGORY_DATA", interestFields, key, selectedValues);
    let updatedSubcategories = [...contact.subcategory];
    const categoryIndex = updatedSubcategories.findIndex(
      (c) => c.cat === subcategories[index].cat
    );
    if (categoryIndex !== -1) {
      const subcatIndex = updatedSubcategories[categoryIndex].values.findIndex(
        (v) => v.key === key
      );

      if (subcatIndex !== -1) {
        // Directly assign selectedValues if it's already an array
        updatedSubcategories[categoryIndex].values[
          subcatIndex
        ].value = selectedValues;
      } else {
        updatedSubcategories[categoryIndex].values.push({
          key,
          value: selectedValues,
        });
      }
    } else {
      updatedSubcategories.push({
        cat: interestFields[index].categoryId,
        values: [{ key, value: selectedValues }],
      });
    }

    // Update the interestFields to merge values for the same key instead of adding duplicates
    let updatedInterestFields = [...interestFields];
    const existingSubcatIndex = updatedInterestFields[
      index
    ].subcategories.findIndex((subcat) => subcat.key === key);

    if (existingSubcatIndex !== -1) {
      // If the key already exists, merge the new values with the existing ones
      updatedInterestFields[index].subcategories[existingSubcatIndex] = {
        key: key,
        value: [
          ...new Set([
            ...updatedInterestFields[index].subcategories[existingSubcatIndex]
              .value,
            ...selectedValues,
          ]),
        ],
      };
    } else {
      // If the key does not exist, just add it as a new subcategory
      updatedInterestFields[index].subcategories.push({
        key: key,
        value: selectedValues,
      });
    }

    setInterestFields(updatedInterestFields);
    setContact({ ...contact, subcategory: updatedSubcategories });
  };

  const handleChange = (element, index, subcategoryKey) => (event) => {
    const value = event.target.value;
    if (element === "subcategory") {
      // console.log("ENTRO: ", subcategoryKey, index, value, element);
      // console.log("===INTEREST FIELDS===: ", interestFields);
      updateSubcategoryData(subcategoryKey, value, index);
      return;
    }
    if (element === "category") {
      let newInterestFields = [...interestFields];
      newInterestFields[subcategoryKey] = {
        ...newInterestFields[subcategoryKey],
        categoryId: value,
        subcategories: [],
      };
      setInterestFields(newInterestFields);

      const selectedCategory = categories.find((c) => c._id === value);
      console.log("SELECTED CATEGORY: ", selectedCategory);
      const subcategoriesArray = Object.entries(
        selectedCategory.subcategories
      ).map(([key, value]) => ({ key: value.key, value: value.value }));
      const newSubcategories = [
        ...subcategories,
        {
          cat: value,
          values: subcategoriesArray,
        },
      ];
      console.log(newSubcategories);
      setSubcategories(newSubcategories);

      let newCategories = [...contact.category];
      newCategories[subcategoryKey] = value;
      setContact({
        ...contact,
        category: newCategories,
      });
      return;
    }
    if (element === "wantsInfo") {
      setContact({ ...contact, [element]: !contact.wantsInfo });
      return;
    }
    setContact({ ...contact, [element]: event.target.value });
  };

  const CustomInputText = () => {
    return (
      <FormControl fullWidth>
        <Autocomplete
          id="localidad-autocomplete"
          options={localidades}
          getOptionLabel={(option) => option.fullName}
          value={
            localidades.find((loc) => loc._id === contact.localidad) || null
          }
          onChange={(event, newValue) => {
            handleChange("localidad")({
              target: { value: newValue ? newValue._id : "" },
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Localidad"
              variant="outlined"
              required
            />
          )}
        />
      </FormControl>
    );
  };

  const groupSubcategories = (subcategoriesList) => {
    const uniqueSubcategories = subcategoriesList.filter(
      (subcat, index, self) =>
        index ===
        self.findIndex((s) => s.key === subcat.key && s.value === subcat.value)
    );

    const finalSubcategories = uniqueSubcategories.reduce((grouped, subcat) => {
      const valuesArray = subcat.values;
      valuesArray.forEach((valueObj) => {
        const { key, value } = valueObj;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        if (Array.isArray(value)) {
          grouped[key] = [...grouped[key], ...value];
        } else {
          grouped[key].push(value);
        }
      });
      return grouped;
    }, {});
    return finalSubcategories;
  };

  const renderSubcategoryComponent = (index, categoryId) => {
    const selectedSubcategories = subcategories.filter(
      (subcat) => subcat.cat === categoryId
    );

    const groupedSubcategories = groupSubcategories(selectedSubcategories);

    return (
      <Grid container spacing={10}>
        {Object.entries(groupedSubcategories).map(([key, values]) => {
          const selectedValues = interestFields[index].subcategories
            .filter((subcat) => subcat.key === key)
            .flatMap((subcat) => subcat.value);

          return (
            <Grid item xs={12} sm={6} md={4} key={`${index}-${key}`}>
              {values.includes("") ? (
                <TextField
                  label={key}
                  value={selectedValues[0] || ""}
                  onChange={handleChange("subcategory", index, key)}
                  style={{ margin: 8, maxWidth: "100%" }}
                  margin="normal"
                  variant="outlined"
                />
              ) : (
                <FormControl fullWidth style={{ margin: 8 }}>
                  <InputLabel
                    id={`${index}-${key}-label`}
                  >{`Select ${key}`}</InputLabel>
                  <Select
                    labelId={`${index}-${key}-label`}
                    id={`${index}-${key}-select`}
                    multiple
                    value={selectedValues}
                    onChange={handleChange("subcategory", index, key)}
                    renderValue={(selected) =>
                      Array.isArray(selected) ? selected.join(", ") : selected
                    }
                  >
                    {values.map((value, valueIndex) => (
                      <MenuItem key={valueIndex} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const addInterestsField = () => {
    setInterestFields([
      ...interestFields,
      { categoryId: "", subcategories: [] },
    ]);
  };

  const removeInterestsField = (index) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de querer eliminar este campo?"
    );

    if (confirmDelete) {
      // Delete the field
      const newSubCategories = interestFields?.filter(
        (_, attrIndex) => attrIndex !== index
      );
      setContact({
        ...contact,
        subcategory: contact.subcategory.filter(
          (_, subcatIndex) => subcatIndex !== index
        ),
        category: contact.category.filter((_, catIndex) => catIndex !== index),
      });
      setInterestFields(newSubCategories);
      alertInfo({ message: "Campo eliminado correctamente" });
    } else {
      alertInfo({ message: "Operación cancelada" });
    }
  };

  const interestsFields =
    interestFields?.length > 0 &&
    interestFields.map((interest, index) => (
      <>
        <div key={index}>
          <FormControl className="col-8">
            <InputLabel id={`categoria-${index}`}>Categoría</InputLabel>
            <Select
              labelId={`categoria-${index}`}
              id={`categoria-${index}`}
              value={interestFields[index].categoryId}
              onChange={handleChange("category", null, index)}
              required
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => removeInterestsField(index)}
            style={{ fontSize: "1rem", color: "#f64e60" }}
            className="col-4"
          >
            <span>ELIMINA INTERÉS</span>
            <DeleteIcon />
          </IconButton>
        </div>
        <section className="d-flex flex-column">
          {interestFields[index].categoryId &&
            interestFields[index].categoryId.length > 0 &&
            renderSubcategoryComponent(index, interestFields[index].categoryId)}
        </section>
      </>
    ));

  useEffect(() => {
    console.log("interestFields", interestFields);
  }, [interestFields, contact.subcategory]);

  useEffect(() => {
    console.log("contact", contact);
  }, [contact]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit contact"></CardHeader>
          <CardBody>
            <TextField
              id={`nombre`}
              label="Nombre"
              value={contact.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <TextField
              id={`telefono fijo`}
              label="Teléfono fijo"
              value={contact.telFij}
              onChange={handleChange("telFij")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <span>
              Añade el teléfono con el prefijo pertinente ejemplo: +34629366666
            </span>
            <TextField
              id={`telefono movil`}
              label="Teléfono móvil"
              value={contact.telMob}
              onChange={handleChange("telMob")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`email`}
              label="Email"
              value={contact.email}
              onChange={handleChange("email")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`fecha nacimiento`}
              label="Fecha nacimiento"
              type="date"
              value={
                isNaN(new Date(contact.fechaNacimiento).getTime())
                  ? ""
                  : new Date(contact.fechaNacimiento)
                      .toISOString()
                      .split("T")[0]
              }
              onChange={handleChange("fechaNacimiento")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`fecha contacto`}
              label="Fecha contacto"
              type="date"
              value={
                isNaN(new Date(contact.fechaContacto).getTime())
                  ? ""
                  : new Date(contact.fechaContacto).toISOString().split("T")[0]
              }
              onChange={handleChange("fechaContacto")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`nreferencia`}
              label="Nº Referencia"
              type="text"
              value={contact.nreferencia}
              onChange={handleChange("nreferencia")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <span>¿Quiere recibir información?</span>
            <Switch
              checked={contact.wantsInfo}
              onChange={handleChange("wantsInfo")}
              name="WantsInfo"
              inputProps={{ "aria-label": "WantsInfo switch" }}
            />
            <br />
            <br />
            <section className="d-flex justify-content-around my-5">
              <>
                <Autocomplete
                  multiple
                  id="lists-standard"
                  options={segments.lists}
                  getOptionLabel={(option) => option.name}
                  value={contact.listIds ? contact.listIds : []}
                  onChange={(event, newValue) => {
                    setContact({
                      ...contact,
                      listIds: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Lists"
                      placeholder="Lists"
                    />
                  )}
                  className="w-50 m-2"
                />
                <Autocomplete
                  multiple
                  id="segments-standard"
                  options={segments.segments}
                  getOptionLabel={(option) => option.segmentName}
                  value={contact.segmentIds ? contact.segmentIds : []}
                  onChange={(event, newValue) => {
                    setContact({
                      ...contact,
                      segmentIds: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Segments"
                      placeholder="Segments"
                    />
                  )}
                  className="w-50 m-2"
                />
              </>
            </section>
            <br />
            <br />
            <TextField
              id={`observaciones`}
              label="Observaciones"
              multiline
              minRows={4}
              value={contact.observations}
              onChange={handleChange("observations")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <div className="d-flex align-items-center justify-content-around">
              <div className="col-4">
                <FormControl fullWidth>
                  <InputLabel id="tipo-contacto">Tipo de contacto</InputLabel>
                  <Select
                    labelId="tipo-contacto"
                    id="tipo-contacto-select"
                    value={contact.contactType}
                    onChange={handleChange("contactType")}
                    required
                  >
                    <MenuItem value="cliente">Cliente</MenuItem>
                    <MenuItem value="interesado">Interesado</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl fullWidth>
                  <InputLabel id="contactado-por">Contacted by</InputLabel>
                  <Select
                    labelId="contactado-por"
                    id="contacted-by-select"
                    value={contact.contactedBy}
                    onChange={handleChange("contactedBy")}
                    required
                  >
                    {users.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-4">
                <FormControl fullWidth>
                  <InputLabel id="origen">Origen</InputLabel>
                  <Select
                    labelId="origen"
                    id="origin-select"
                    value={contact.origin}
                    onChange={handleChange("origin")}
                    required
                  >
                    {origins.map((origin) => (
                      <MenuItem key={origin._id} value={origin._id}>
                        {origin.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <br />
            <br />
            <div className="d-flex align-items-center justify-content-around">
              <div className="col-6">
                <CustomInputText />
              </div>
              <div className="col-6">
                <TextField
                  id={`direccion`}
                  label="Dirección"
                  value={contact.address}
                  onChange={handleChange("address")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <br />
            <br />
            <h2>Intereses</h2>
            <span className="d-flex align-self-center">
              ¿En que está interesado el cliente?
            </span>
            {interestsFields}
            <Button onClick={addInterestsField} className="btn btn-primary m-5">
              Add More Fields
            </Button>
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/contacts")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveContact()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save contact
        </Button>
        {contactId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete contact
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this contact?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteContact(contactId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Contact deleted successfully",
                      });
                      postLog("Contacts", `delete-${contactId}`, user.fullName);
                      history.push("/contacts");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete contact.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
