import { /* baseClient, */ authClient, API } from "../index";

// Get all categories
export const getCategories = () => {
  return authClient().get(`${API}/category`);
};

// Get category by id
export const getCategoryById = (id) => {
  return authClient().get(`${API}/category/${id}`);
};

// Delete category
export const deleteCategory = (id) => {
  return authClient().delete(`${API}/category/${id}`);
};

// Create category
export const postCategory = (category) => {
  return authClient().post(`${API}/category`, category);
};

// Update category
export const updateCategory = (id, category) => {
  return authClient().put(`${API}/category/${id}`, category);
};

// change category status
export const changeStatusCategory = (id) => {
  return authClient().put(`${API}/category/changeStatus/${id}`);
};
