import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Select } from "@material-ui/core";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { importData } from "../../../../api/order";
import { postLog } from "../../../../api/log";

export default function ImportDataPage() {
  const user = useSelector((store) => store.authentication?.user);
  const [profile, setProfile] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelColumns, setExcelColumns] = useState({
    productCode: "A",
    description: "E",
    shortDescription: "D",
    imageUrl: "K",
    imageUrl2: "",
    imageUrl3: "",
  });
  const [excelAvailableColumns, setExcelAvailableColumns] = useState([]);

  const handleColumnSelect = (column, value) => {
    setExcelColumns((prevColumns) => ({
      ...prevColumns,
      [column]: value,
    }));
  };

  const handleImportExcel = (e) => {
    console.log(e);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log(excelData);
        setExcelData({ name: "ImportData", data: excelData });
        setExcelAvailableColumns(
          excelData[1].map((_, index) => XLSX.utils.encode_col(index))
        );
        alertSuccess("Excel file imported successfully.");
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const saveExcelData = async () => {
    setSpinner(true);
    const data = {
      excelData: excelData.data,
      columns: excelColumns,
    };
    console.log(data);
    try {
      // const response = await importExcelData(data);
      const response = await importData(data);
      if (response.status === 200) {
        alertSuccess({ CustomMessage: "Excel data imported successfully." });
        postLog("importData", "visualize", user.fullName);
      } else alertError({ error: "Error importing excel data." });
      setSpinner(false);
    } catch (error) {
      alertError("Error importing excel data.");
      setSpinner(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  const Spinner = spinner ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <div className="d-flex jusitfy-center align-center">
        <div
          className="spinner-grow"
          style={{ width: "5rem", height: "5rem", marginTop: "20rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  // useEffect(() => {
  //   console.log(excelColumns);
  // }, [excelColumns]);

  return (
    <>
      <Card>
        <CardHeader title="Import Excel Data" />
        <CardBody style={{ position: "relative", padding: "20px" }}>
          <span>
            <strong>
              Realiza la importación del excel y selecciona las columnas que
              contengan el valor especificado
            </strong>
          </span>
          {Spinner}
          <div style={{ maxWidth: "700px", margin: "auto" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleImportExcel}
                style={{ display: "none" }}
                id="uploadExcel"
              />
              <label
                htmlFor="uploadExcel"
                className="btn btn-primary btn-block my-5"
                style={{ width: "25%", padding: "10px 0" }}
              >
                Import Excel
              </label>
              {excelData.name && (
                <span style={{ alignSelf: "center", margin: "10px" }}>
                  {excelData.name}
                </span>
              )}
            </div>
            <h5>Excel Data</h5>
            <section
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ marginTop: "20px" }}>
                <label htmlFor="productCodeColumn" className="form-label">
                  Product reference Column:
                </label>
                <Select
                  id="productCodeColumn"
                  className="form-control"
                  value={excelColumns.productCode}
                  onChange={(e) =>
                    handleColumnSelect("productCode", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <label htmlFor="descriptionColumn" className="form-label">
                  Description Column:
                </label>
                <Select
                  id="descriptionColumn"
                  className="form-control"
                  value={excelColumns.description}
                  onChange={(e) =>
                    handleColumnSelect("description", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <label htmlFor="shortDescriptionColumn" className="form-label">
                  Short Description Column:
                </label>
                <Select
                  id="shortDescriptionColumn"
                  className="form-control"
                  value={excelColumns.shortDescription}
                  onChange={(e) =>
                    handleColumnSelect("shortDescription", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <label htmlFor="imageUrlColumn" className="form-label">
                  Image URL Column:
                </label>
                <Select
                  id="imageUrlColumn"
                  className="form-control"
                  value={excelColumns.imageUrl}
                  onChange={(e) =>
                    handleColumnSelect("imageUrl", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <label htmlFor="imageUrlColumn" className="form-label">
                  Image URL 2 Column:
                </label>
                <Select
                  id="imageUrl2Column"
                  className="form-control"
                  value={excelColumns.imageUrl2}
                  onChange={(e) =>
                    handleColumnSelect("imageUrl2", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
              <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                <label htmlFor="imageUrl3Column" className="form-label">
                  Image URL 3 Column:
                </label>
                <Select
                  id="imageUrl3Column"
                  className="form-control"
                  value={excelColumns.imageUrl3}
                  onChange={(e) =>
                    handleColumnSelect("imageUrl3", e.target.value)
                  }
                >
                  {excelAvailableColumns.map((column) => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Select>
              </div>
            </section>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                className="btn btn-primary btn-block my-5"
                onClick={saveExcelData}
                style={{ width: "50%", padding: "10px 0" }}
              >
                Start Sync
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
