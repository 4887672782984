import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getComunidades,
  deleteComunidad,
  changeStatusComunidad,
} from "../../../../api/comunidad";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

function getData(comunidades) {
  let data = [];
  for (let i = 0; i < comunidades.length; ++i) {
    console.log(comunidades);
    const elem = {};
    elem.name = comunidades[i].name;
    elem.createdAt = comunidades[i].createdAt;
    elem.id = comunidades[i]._id;
    elem.active = comunidades[i].active;
    data = data.concat(elem);
  }
  return data;
}

export default function ComunidadesPage() {
  const [data, setData] = useState([]);
  const [comunidadId, setComunidadId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  function buttonFormatter(cell) {
    const elem = data.find((item) => item.id === cell);
    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-comunidad/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setComunidadId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setComunidadId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getComunidades()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setRefresh(false);
          postLog("comunidades-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las comunidades.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Lista de Comunidades">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-comunidad")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={`¿Estás seguro de que quieres ${
              comunidadId?.active ? "desactivar" : "activar"
            } esta comunidad?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusComunidad(comunidadId.id, !comunidadId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    postLog("Comunidades-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${
                        comunidadId?.active ? "¡Desactivada!" : "¡Activada!"
                      }`,
                      customMessage: `Comunidad ${
                        comunidadId?.active ? "desactivada" : "activada"
                      } correctamente`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `No se ha podido ${
                      comunidadId?.active ? "desactivar" : "activar"
                    } esta comunidad.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta comunidad?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteComunidad(comunidadId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "Comunidad eliminada correctamente.",
                    });
                    postLog("Comunidad", "delete", user.fullName);
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la comunidad.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
