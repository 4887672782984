import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getCategories,
  deleteCategory,
  changeStatusCategory,
} from "../../../../api/category";
import { Button, Tooltip, Switch, FormControlLabel } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import FiltersCard from "../../../components/filters/Filter";
import { postLog } from "../../../../api/log";

function getData(categories) {
  let data = [];
  for (let i = 0; i < categories.length; ++i) {
    const elem = {};
    elem.title = categories[i].title;
    elem.description = categories[i].description;
    elem.createdAt = categories[i].createdAt;
    elem.id = categories[i]._id;
    elem.active = categories[i].active;
    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  active: false,
};

export default function CategoriesPage() {
  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [filterOptions, setFilterOptions] = useState(initialFilters);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  useEffect(() => {
    console.log("user", user);
  }, []);

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-category/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setCategoryId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setCategoryId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "title", text: "Title", sort: true },
    { dataField: "description", text: "Description", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
          setCategories(res.data);
          setRefresh(false);
          postLog("categories-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get categories.",
        });
      });
  }, [refresh]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.active) filter = filter && item.active;
        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
    setRefresh(true);
  };

  const renderFiltersContent = () => {
    return (
      <>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.active}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  active: !filterOptions.active,
                })
              }
              name="checkActive"
            />
          }
          label={filterOptions.active ? "Only active" : "All active/inactive"}
        />
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Categories list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-category")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table data={getData(filteredData, categories)} columns={columns} />

          <ConfirmDialog
            title={`Are you sure you want to ${
              categoryId?.active ? "disable" : "enable"
            } this category?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusCategory(categoryId.id, !categoryId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    postLog("categories-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${categoryId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Category ${
                        categoryId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      categoryId?.active ? "disable" : "enable"
                    } category.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this category?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCategory(categoryId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Category removed successfully.",
                    });
                    postLog("Category", "delete", user.fullName);
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove category.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
