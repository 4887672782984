import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  MuiThemeProvider,
  Checkbox,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  createMuiTheme,
} from "@material-ui/core";
import Table, { booleanFormatter, buttonsStyle } from "../tables/table";
import { FiltersCard } from "../filters/Filter";
import { SERVER_URL } from "../../../api";
import PreviewDialog from "./PreviewDialog";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const initialFilters = {
  active: false,
  wantsInfo: true,
  category: [],
  subcategory: [],
};

const TableDialog = (props) => {
  const {
    title,
    open,
    setOpen,
    data,
    localidades,
    categorias,
    subcategorias,
    saveUsers,
  } = props;
  const [filteredData, setFilteredData] = useState([]);
  const [finalUsersData, setFinalUsersData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [categories, setCategories] = useState(categorias);
  const [subcategories, setSubcategories] = useState(subcategorias);
  const [filterOptions, setFilterOptions] = useState(initialFilters);

  useEffect(() => {
    setFilteredData(data);
  }, []);

  //AGRUPAR SUBCATEGORIES
  const groupSubcategories = (sub) => {
    const groupedSubcategories = sub.reduce((result, subcategory) => {
      const { key, value } = subcategory;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
      return result;
    }, {});
    return groupedSubcategories;
  };

  //FILTRES
  const isActiveFilterPassed = (item, active) => !active || item.active;

  const wantsInfoFilterPassed = (item, wantsInfo) =>
    !wantsInfo || item.wantsInfo;

  //PASSA EL FILTRE DE CATEGORIES
  const isCategoryFilterPassed = (item, categories) =>
    !categories ||
    !categories.length ||
    categories.some((category) => item.category.includes(category));

  //PASSA EL FILTRE DE SUBCATEGORIES
  const isSubcategoryFilterPassed = (item, subcategories) =>
    !subcategories ||
    !subcategories.length ||
    subcategories.every((subcategory) =>
      item.subcategory.find((sub) =>
        sub.values.some((value) =>
          Array.isArray(value.value)
            ? value.value.some((val) => subcategory.values.includes(val))
            : subcategory.values === value.value
        )
      )
    );

  //FAIG CRIDA ALS DIFERENTS FILTRES
  const filterData = (data, filterOptions) => {
    return data.filter((item) => {
      let filter =
        isActiveFilterPassed(item, filterOptions.active) &&
        wantsInfoFilterPassed(item, filterOptions.wantsInfo) &&
        isCategoryFilterPassed(item, filterOptions.category) &&
        isSubcategoryFilterPassed(item, filterOptions.subcategory);

      return filter;
    });
  };

  const handleCheckSelection = () => {
    const newData = filteredData.map((item) => ({
      ...item,
      selected: true,
    }));
    const newFinalUsersData = [...finalUsersData];
    newData.forEach((user) => {
      if (!finalUsersData.some((finalUser) => finalUser._id === user._id)) {
        newFinalUsersData.push(user);
      }
    });

    setFilteredData(newData);
    setFinalUsersData(newFinalUsersData);
  };

  const handleDeleteSelection = () => {
    const newData = filteredData.map((item) => ({
      ...item,
      selected: false,
    }));
    setFilteredData(newData);

    const idsOfVisibleDeselectedUsers = new Set(
      newData.map((user) => user._id)
    );
    const newFinalUsersData = finalUsersData.filter(
      (user) => !idsOfVisibleDeselectedUsers.has(user._id)
    );

    setFinalUsersData(newFinalUsersData);
  };

  const handleSaveSelection = () => {
    saveUsers(finalUsersData);
    setOpen(false);
  };

  const handleSearch = async () => {
    if (!data.length) return;

    const filteredData = filterData(data, filterOptions);
    setFilteredData(filteredData);
  };

  const handleChange = (element, key) => (event) => {
    if (element === "category") {
      let subcategories = [];
      let newFilterOptionsSubcategory = [];
      event.target.value.forEach((categoryId) => {
        const category = categories.find(
          (category) => category._id === categoryId
        );
        category.subcategories.forEach((subcategory) => {
          subcategories.push({
            key: subcategory.key,
            value: subcategory.value,
          });
          const existingFilter = filterOptions.subcategory.find(
            (f) => f.key === subcategory.key
          );
          if (existingFilter) {
            newFilterOptionsSubcategory.push(existingFilter);
          }
        });
      });
      setSubcategories(groupSubcategories(subcategories));
      setFilterOptions((prev) => ({
        ...prev,
        category: event.target.value,
        subcategory: newFilterOptionsSubcategory,
      }));
    }
    if (element === "subcategory") {
      let subcats = [...(filterOptions.subcategory || [])];
      if (key) {
        const existingSubcategory = subcats.find(
          (subcategory) => subcategory.key === key
        );
        if (existingSubcategory) {
          existingSubcategory.values = event.target.value;
        } else {
          subcats.push({
            key: key,
            values: event.target.value,
          });
        }
        setFilterOptions({
          ...filterOptions,
          [element]: subcats,
        });
        return;
      }
      const name = event.target.name;
      const selectedOptions = event.target.value;
      const index = subcats.findIndex((subcat) => subcat.key === name);
      if (index > -1) {
        subcats[index].values = selectedOptions;
      } else {
        subcats.push({ key: name, values: selectedOptions });
      }
      setFilterOptions({
        ...filterOptions,
        [element]: subcats,
      });
      return;
    }
    setFilterOptions({ ...filterOptions, [element]: event.target.value });
  };

  //ESBORRO ELS FILTRES
  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
  };

  //RENDERITZACIÓ DELS FILTRES
  const renderFiltersContent = () => {
    return (
      <>
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={filterOptions.category || []}
            multiple
            onChange={handleChange("category")}
            MenuProps={MenuProps}
          >
            {categories?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <br />
        <>
          {filterOptions.category.length > 0 &&
            Object.entries(subcategories).map(([key, value]) => (
              <FormControl style={{ width: "100%" }} key={key}>
                {value != "" ? (
                  <>
                    <InputLabel id={`${key}-select-label`}>{key}</InputLabel>
                    <Select
                      labelId={`${key}-select-label`}
                      id={`${key}-select`}
                      value={
                        filterOptions.subcategory.find(
                          (subcat) => subcat.key === key
                        )?.values || []
                      }
                      multiple
                      onChange={handleChange("subcategory")}
                      MenuProps={MenuProps}
                      name={key}
                    >
                      {value.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <TextField
                    id={`${key}-text-field`}
                    label={`${key}`}
                    value={
                      filterOptions.subcategory.find(
                        (subcat) => subcat.key === key
                      )?.values || ""
                    }
                    onChange={handleChange("subcategory", key)}
                    margin="normal"
                  />
                )}
              </FormControl>
            ))}
        </>
        <br />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={filterOptions.wantsInfo}
              onChange={() =>
                setFilterOptions({
                  ...filterOptions,
                  wantsInfo: !filterOptions.wantsInfo,
                })
              }
              name="checkWantsInfo"
            />
          }
          label={
            filterOptions.wantsInfo
              ? "Quiere información"
              : "No quiere información"
          }
        />
        <br />
        <br />
        <section className="d-flex">
          <Tooltip title="Select All" className="m-5 p-5">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() => {
                setFilteredData(
                  data.map((item) => ({ ...item, selected: true }))
                );
                setFinalUsersData(data);
              }}
            >
              Select All
            </Button>
          </Tooltip>
          <Tooltip title="Deselect All" className="m-5 p-5">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() => {
                setFilteredData(
                  data.map((item) => ({ ...item, selected: false }))
                );
                setFinalUsersData([]);
              }}
            >
              Deselect All
            </Button>
          </Tooltip>
          <Tooltip title="Check Selection" className="m-5 p-5">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={handleCheckSelection}
            >
              Check Selection
            </Button>
          </Tooltip>
          <Tooltip title="Clear Selection" className="m-5 p-5">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={handleDeleteSelection}
            >
              Clear Selection
            </Button>
          </Tooltip>
        </section>
        <br />
        <br />
        <span>Total usuarios seleccionados: {finalUsersData.length}</span>
      </>
    );
  };

  // FORMATTER PER ALS BOTONS DE SELECCIÓ
  function buttonFormatter(cell, row) {
    console.log("ROW: ", row);
    console.log(cell);
    return (
      <Tooltip title="Select/Deselect">
        <Checkbox
          checked={
            filteredData.find((user) => user._id === cell)?.selected || false
          }
          onChange={() => {
            const newData = filteredData.map((item) => {
              if (item._id === cell) {
                return { ...item, selected: !item.selected };
              }
              return item;
            });
            setFilteredData(newData);
            const updatedSelection = newData.find((user) => user._id === cell);
            const userIndexInFinal = finalUsersData.findIndex(
              (user) => user._id === cell
            );
            if (updatedSelection && updatedSelection.selected) {
              if (userIndexInFinal === -1) {
                setFinalUsersData([...finalUsersData, updatedSelection]);
              }
            } else {
              if (userIndexInFinal !== -1) {
                setFinalUsersData(
                  finalUsersData.filter((user) => user._id !== cell)
                );
              }
            }
          }}
          color="primary"
        />
      </Tooltip>
    );
  }

  // FORMATTER PER ALS TEXTOS LLARGS
  function longTextFormatter(cell) {
    if (cell === undefined) return <td></td>;
    return (
      <td className="truncate">
        <div
          dangerouslySetInnerHTML={{
            __html: Array.isArray(cell)
              ? cell.join(", ").substring(0, 10) +
                (cell.join(", ").length > 10 ? "..." : "")
              : cell.substring(0, 50) + (cell.length > 50 ? "..." : ""),
          }}
        ></div>
      </td>
    );
  }

  // FORMATTER PER A LES LOCALITATS
  function localidadesFormatter(cell) {
    const localidad = localidades.find((loc) => loc._id === cell);
    return (
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html:
              localidad?.fullName.substring(0, 50) +
              (localidad?.fullName.length > 50 ? "..." : ""),
          }}
        />
      </td>
    );
  }

  const columns = [
    {
      dataField: "fullName",
      text: "Nombre completo",
      sort: true,
      formatter: longTextFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: longTextFormatter,
    },
    {
      dataField: "telMob",
      text: "Teléfono Móvil",
      sort: true,
      formatter: longTextFormatter,
    },
    {
      dataField: "telFij",
      text: "Teléfono fijo",
      sort: true,
      formatter: longTextFormatter,
    },
    {
      dataField: "localidad",
      text: "Localidad",
      sort: true,
      formatter: localidadesFormatter,
    },
    {
      dataField: "wantsInfo",
      text: "Quiere información",
      sort: true,
      formatter: booleanFormatter,
    },
    {
      dataField: "_id",
      text: "Seleccionado",
      sort: true,
      formatter: buttonFormatter,
    },
  ];

  // useEffect(() => {
  //   console.log("SELECTED USERS: ", filteredData);
  // }, [filteredData]);

  // useEffect(() => {
  //   console.log("FINAL USERS: ", finalUsersData);
  // }, [finalUsersData]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="table-dialog"
    >
      <DialogTitle id="table-dialog">{title}</DialogTitle>
      <DialogContent>
        <>
          <FiltersCard
            filtersContent={renderFiltersContent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
          />

          <Table data={filteredData} columns={columns} />
        </>
      </DialogContent>
      <DialogActions>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveSelection}
            variant="outlined"
            color="primary"
          >
            Save Selection
          </Button>
        </MuiThemeProvider>
      </DialogActions>
    </Dialog>
  );
};
export default TableDialog;
