import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCategory,
  getCategoryById,
  postCategory,
  updateCategory,
} from "../../../../api/category";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyCategory() {
  return {
    title: "",
    description: "",
    subcategories: {},
    active: true,
  };
}

export default function EditCategoriesPage() {
  const [category, setCategory] = useState(getEmptyCategory());
  const [subcategories, setSubCategories] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const categoryId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!categoryId) {
      disableLoadingData();
      return;
    }

    getCategoryById(categoryId)
      .then((res) => {
        if (res.status === 200) {
          const category = res.data;
          setCategory(category);
          setSubCategories(
            category.subcategories
              ? category.subcategories.length > 0
                ? category.subcategories
                : []
              : []
          );
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get category." });
        history.push("/categories");
      });
  }, [categoryId, disableLoadingData, history]);

  function saveCategory() {
    let saveCategory = category;
    if (subcategories.length > 0) saveCategory.subcategories = subcategories;
    console.log("SAVE CATEGORY:", saveCategory);
    if (!categoryId) {
      postCategory(saveCategory)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Category successfully created.",
            });
            postLog("Categories", `create-${categoryId}`, user.fullName);
            history.push("/categories");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save category.",
          });
        });
    } else {
      updateCategory(categoryId, saveCategory)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Categories", `update-${categoryId}`, user.fullName);
            history.push("/categories");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setCategory({ ...category, [element]: event.target.value });
  };

  const handleSubCategoryChange = (index, key, value) => {
    const newSubCategories = [...subcategories];
    newSubCategories[index] = { ...newSubCategories[index], [key]: value };
    setSubCategories(newSubCategories);
  };

  const addSubCategoryField = () => {
    setSubCategories([...subcategories, { key: "", value: "" }]);
  };

  const removeSubCategoryField = (index) => {
    const newSubCategories = subcategories?.filter(
      (_, attrIndex) => attrIndex !== index
    );
    setSubCategories(newSubCategories);
  };

  const subcategoryFields =
    subcategories?.length > 0 &&
    subcategories.map((subcategory, index) => (
      <div
        key={index}
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <TextField
          label="Key"
          value={subcategory.key || ""}
          onChange={(e) =>
            handleSubCategoryChange(index, "key", e.target.value)
          }
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Value"
          value={subcategory.value || ""}
          onChange={(e) =>
            handleSubCategoryChange(index, "value", e.target.value)
          }
          margin="normal"
          variant="outlined"
        />
        <IconButton onClick={() => removeSubCategoryField(index)}>
          <DeleteIcon />
        </IconButton>
      </div>
    ));

  useEffect(() => {
    console.log("category", category);
    console.log("subcategories", subcategories);
  }, [category]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit category"></CardHeader>
          <CardBody>
            <TextField
              id={`título`}
              label="Título"
              value={category.title}
              onChange={handleChange("title")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <br />
            <TextField
              id={`descripcion`}
              label="Descripcion"
              multiline
              minRows={4}
              value={category.description}
              onChange={handleChange("description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            {subcategoryFields}
            <Button onClick={addSubCategoryField}>Add More Fields</Button>
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.active}
                  onChange={() =>
                    setCategory({ ...category, active: !category.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/categories")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveCategory()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save category
        </Button>
        {categoryId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete category
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this category?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteCategory(categoryId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Category deleted successfully",
                      });
                      postLog(
                        "Categories",
                        `delete-${categoryId}`,
                        user.fullName
                      );
                      history.push("/categories");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete category.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
