import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCharge,
  getChargeById,
  postCharge,
  updateCharge,
} from "../../../../api/charge";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyCharge() {
  return {
    chargeName: "",
  };
}

export default function EditChargesPage() {
  const [charge, setCharge] = useState(getEmptyCharge());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const chargeId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!chargeId) {
      disableLoadingData();
      return;
    }

    getChargeById(chargeId)
      .then((res) => {
        if (res.status === 200) {
          const charge = res.data;
          setCharge(charge);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get charge." });
        history.push("/charges");
      });
  }, [chargeId, disableLoadingData, history]);

  function saveCharge() {
    let saveCharge = charge;
    if (!chargeId) {
      postCharge(saveCharge)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Charge successfully created.",
            });
            postLog("Charges", `create-${chargeId}`, user.fullName);
            history.push("/charges");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save charge.",
          });
        });
    } else {
      updateCharge(chargeId, saveCharge)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Charges", `update-${chargeId}`, user.fullName);
            history.push("/charges");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setCharge({ ...charge, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit charge"></CardHeader>
          <CardBody>
            <TextField
              id={`chargeName`}
              label="Nombre del cargo"
              value={charge.chargeName}
              onChange={handleChange("chargeName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/charges")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveCharge()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save charge
        </Button>
        {chargeId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete charge
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this charge?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteCharge(chargeId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Charge deleted successfully",
                      });
                      postLog("Charges", `delete-${chargeId}`, user.fullName);
                      history.push("/charges");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete charge.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
