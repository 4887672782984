import { /* baseClient, */ authClient, API } from "../index";

// Get all provincias
export const getProvincias = () => {
  return authClient().get(`${API}/provincia`);
};

// Get provincia by id
export const getProvinciaById = (id) => {
  return authClient().get(`${API}/provincia/${id}`);
};

// Delete provincia
export const deleteProvincia = (id) => {
  return authClient().delete(`${API}/provincia/${id}`);
};

// Create provincia
export const postProvincia = (provincia) => {
  return authClient().post(`${API}/provincia`, provincia);
};

// Update provincia
export const updateProvincia = (id, provincia) => {
  return authClient().put(`${API}/provincia/${id}`, provincia);
};
