import { authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all contracts
export const getContracts = () => {
  return authClient().get(`${API}/contract?getDisabled=true`);
};

// Get contract by id
export const getContractById = (id) => {
  return authClient().get(`${API}/contract/${id}`);
};

// Delete contract
export const deleteContract = (id) => {
  return authClient().delete(`${API}/contract/${id}`);
};

// Create contract
export const postContract = async (contract) => {
  return authClient().post(`${API}/contract`, contract);
};

// Update contract
export const updateContract = async (id, contract) => {
  return authClient().put(`${API}/contract/${id}`, contract);
};

// // Count all admins
// export const countAdmins = () => {
//   const role = "admin"
// 	return authClient().get(`${API}/contract/count/${role}`)
// }

// Change status contract
export const changeStatusContract = async (id, active) => {
  return authClient().put(`${API}/contract/change-status/${id}`, { active });
};

export const getContractPdf = async (id) => {
  return authClient().get(`${API}/contract/download-contract/${id}`, {
    responseType: "blob",
  });
};
