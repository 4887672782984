import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createTheme,
  FormControlLabel,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  Modal,
  Box,
  Grid,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deletePersonal,
  getPersonalById,
  postPersonal,
  updatePersonal,
} from "../../../../api/personal";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";
import { getCharges } from "../../../../api/charge";
import { uploadSingleFile } from "../../../../api/files";
import { SERVER_URL } from "../../../../api";

// Create theme for delete button (red)
const theme = createTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyPersonal() {
  return {
    name: "",
    surname: "",
    charge: "",
    email: "",
    phone: "",
    professionalInfo: "",
    documentation: [],
    evalPerformance: [],
    trainingPlans: [],
    courses: [],
    permissionRequest: [],
    active: true,
  };
}

export default function EditPersonalPage() {
  const [personal, setPersonal] = useState(getEmptyPersonal());
  const [charges, setCharges] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalType, setModalType] = useState("");
  const personalId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!personalId) {
      disableLoadingData();
      return;
    }

    getPersonalById(personalId)
      .then((res) => {
        if (res.status === 200) {
          const personal = res.data;

          // Convertir fechas a formato YYYY-MM-DD
          personal.evalPerformance = personal.evalPerformance.map((evalu) => ({
            ...evalu,
            date: evalu.date
              ? new Date(evalu.date).toISOString().split("T")[0]
              : "",
          }));

          personal.trainingPlans = personal.trainingPlans.map((plan) => ({
            ...plan,
            date: plan.date
              ? new Date(plan.date).toISOString().split("T")[0]
              : "",
          }));

          personal.courses = personal.courses.map((course) => ({
            ...course,
            date: course.date
              ? new Date(course.date).toISOString().split("T")[0]
              : "",
          }));

          personal.permissionRequest = personal.permissionRequest.map(
            (request) => ({
              ...request,
              dateReq: request.dateReq
                ? new Date(request.dateReq).toISOString().split("T")[0]
                : "",
              datePer: request.datePer
                ? new Date(request.datePer).toISOString().split("T")[0]
                : "",
            })
          );

          setPersonal(personal);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get personal." });
        history.push("/personal");
      });
  }, [personalId, disableLoadingData, history]);

  useEffect(() => {
    getCharges().then((res) => {
      if (res.status === 200) {
        console.log("DATA: ", res.data);
        setCharges(res.data);
      }
    });
  }, []);

  async function savePersonal() {
    try {
      let savePersonal = { ...personal };
      // Verificar si hay archivos que subir y subirlos
      const uploadedDocs = await Promise.all(
        personal.documentation.map(async (doc) => {
          if (typeof doc === "string") {
            return doc; // Si ya es una URL, devolverla
          } else {
            const response = await uploadSingleFile(
              doc,
              "personal-documentation"
            );
            if (response && response.data && response.data.fileURL) {
              console.log("Uploaded file URL:", response.data.fileURL);
              return response.data.fileURL; // Devolver la URL del documento subido
            } else {
              throw new Error("Error uploading file");
            }
          }
        })
      );

      console.log("Uploaded Docs:", uploadedDocs);

      savePersonal.documentation = uploadedDocs;

      if (!personalId) {
        const res = await postPersonal(savePersonal);
        if (res.status === 201) {
          alertSuccess({
            title: "Saved!",
            customMessage: "Personal successfully created.",
          });
          postLog("Personal", `create-${personalId}`, user.fullName);
          history.push("/personal");
        }
      } else {
        const res = await updatePersonal(personalId, savePersonal);
        if (res.status === 200) {
          alertSuccess({
            title: "Saved!",
            customMessage: "Changes successfully saved.",
          });
          postLog("Personal", `update-${personalId}`, user.fullName);
          history.push("/personal");
        }
      }
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not save personal.",
      });
    }
  }

  const handleChange = (element) => (event) => {
    setPersonal({ ...personal, [element]: event.target.value });
  };

  const handleArrayChange = (arrayName, index, field) => (event) => {
    const updatedArray = [...personal[arrayName]];
    updatedArray[index][field] = event.target.value;
    setPersonal({ ...personal, [arrayName]: updatedArray });
  };

  const handleAddArrayItem = (arrayName, newItem) => {
    console.log(arrayName, newItem);
    setPersonal({
      ...personal,
      [arrayName]: [...personal[arrayName], newItem],
    });
  };

  const handleRemoveArrayItem = (arrayName, index) => {
    const updatedArray = personal[arrayName].filter((item, i) => i !== index);
    setPersonal({ ...personal, [arrayName]: updatedArray });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPersonal({
        ...personal,
        documentation: [...personal.documentation, file],
      });
    }
  };

  const openAddModal = (type) => {
    setModalType(type);
    setModalContent({});
    setOpenModal(true);
  };

  const handleModalChange = (field) => (event) => {
    setModalContent({ ...modalContent, [field]: event.target.value });
  };

  const handleModalSave = () => {
    handleAddArrayItem(modalType, modalContent);
    setOpenModal(false);
  };

  const modalBody = (
    <Box
      position="absolute"
      top="50%"
      left="40%"
      bgcolor="background.paper"
      p={4}
      width={600}
      maxWidth="100%"
      height="auto"
      maxHeight="90vh"
      overflow="auto"
      boxShadow={24}
    >
      {modalType === "evalPerformance" && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha"
                type="date"
                value={modalContent.date || ""}
                onChange={handleModalChange("date")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentarios"
                value={modalContent.comment || ""}
                onChange={handleModalChange("comment")}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </>
      )}
      {modalType === "trainingPlans" && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha"
                type="date"
                value={modalContent.date || ""}
                onChange={handleModalChange("date")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentarios"
                value={modalContent.comment || ""}
                onChange={handleModalChange("comment")}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </>
      )}
      {modalType === "courses" && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha"
                type="date"
                value={modalContent.date || ""}
                onChange={handleModalChange("date")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentarios"
                value={modalContent.comment || ""}
                onChange={handleModalChange("comment")}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </>
      )}
      {modalType === "permissionRequest" && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha de Solicitud"
                type="date"
                value={modalContent.dateReq || ""}
                onChange={handleModalChange("dateReq")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fecha de Permiso"
                type="date"
                value={modalContent.datePer || ""}
                onChange={handleModalChange("datePer")}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comentarios"
                value={modalContent.comment || ""}
                onChange={handleModalChange("comment")}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Button onClick={handleModalSave} variant="outlined" color="primary">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  useEffect(() => {
    console.log("CHARGES", charges);
  }, [charges]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit personal"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Nombre"
              value={personal.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`surname`}
              label="Apellidos"
              value={personal.surname}
              onChange={handleChange("surname")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <label>Seleccionar el cargo</label>
            <Select
              id="charge"
              value={personal.charge}
              onChange={handleChange("charge")}
              displayEmpty
              fullWidth
              variant="outlined"
              margin="normal"
            >
              <MenuItem value="" disabled>
                Seleccionar Cargo
              </MenuItem>
              {charges.map((charge) => (
                <MenuItem key={charge._id} value={charge._id}>
                  {charge.chargeName}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id={`email`}
              label="Email"
              value={personal.email}
              onChange={handleChange("email")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`phone`}
              label="Teléfono"
              value={personal.phone}
              onChange={handleChange("phone")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`professionalInfo`}
              label="Información profesional"
              value={personal.professionalInfo}
              onChange={handleChange("professionalInfo")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <br />
            <br />
            <h4>Documentación adicional</h4>
            <input type="file" onChange={handleFileUpload} />
            {personal?.documentation?.map((doc, index) => (
              <div key={index} className="d-flex flex-row align-items-center">
                {typeof doc === "string" ? (
                  <a
                    href={`${SERVER_URL}/${doc}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Documento {doc.split()} {index + 1}
                  </a>
                ) : (
                  <span>{doc?.name}</span>
                )}
                <IconButton
                  onClick={() => handleRemoveArrayItem("documentation", index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <br />
            <br />
            <h4>Evaluación de Desempeño</h4>
            {personal?.evalPerformance?.map((evalPerformance, index) => (
              <div key={index} className="d-flex flex-row align-items-center">
                <TextField
                  label="Fecha"
                  type="date"
                  value={evalPerformance.date || ""}
                  onChange={handleArrayChange("evalPerformance", index, "date")}
                  InputLabelProps={{ shrink: true }}
                  className="mx-5"
                />
                <TextField
                  label="Comentarios"
                  value={evalPerformance.comment || ""}
                  onChange={handleArrayChange(
                    "evalPerformance",
                    index,
                    "comment"
                  )}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className="mx-2"
                  style={{ width: "50%" }}
                />
                <IconButton
                  onClick={() =>
                    handleRemoveArrayItem("evalPerformance", index)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={() => openAddModal("evalPerformance")}>
              Añadir Evaluación
            </Button>
            <br />
            <br />
            <h4>Planes de Formación</h4>
            {personal?.trainingPlans?.map((plan, index) => (
              <div key={index} className="d-flex flex-row align-items-center">
                <TextField
                  label="Fecha"
                  type="date"
                  value={plan.date || ""}
                  onChange={handleArrayChange("trainingPlans", index, "date")}
                  InputLabelProps={{ shrink: true }}
                  className="mx-5"
                />
                <TextField
                  label="Comentarios"
                  value={plan.comment || ""}
                  onChange={handleArrayChange(
                    "trainingPlans",
                    index,
                    "comment"
                  )}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className="mx-5"
                  style={{ width: "50%" }}
                />
                <IconButton
                  onClick={() => handleRemoveArrayItem("trainingPlans", index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={() => openAddModal("trainingPlans")}>
              Añadir Plan de Formación
            </Button>
            <br />
            <br />
            <h4>Cursos</h4>
            {personal.courses.map((course, index) => (
              <div key={index} className="d-flex flex-row align-items-center">
                <TextField
                  label="Fecha"
                  type="date"
                  value={course.date || ""}
                  onChange={handleArrayChange("courses", index, "date")}
                  InputLabelProps={{ shrink: true }}
                  className="mx-5"
                />
                <TextField
                  label="Comentarios"
                  value={course.comment || ""}
                  onChange={handleArrayChange("courses", index, "comment")}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className="mx-5"
                  style={{ width: "50%" }}
                />
                <IconButton
                  onClick={() => handleRemoveArrayItem("courses", index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={() => openAddModal("courses")}>
              Añadir Curso
            </Button>
            <br />
            <br />
            <h4>Solicitud de Permisos</h4>
            {personal?.permissionRequest?.map((request, index) => (
              <div key={index} className="d-flex flex-row align-items-center">
                <TextField
                  label="Fecha de Solicitud"
                  type="date"
                  value={request.dateReq || ""}
                  onChange={handleArrayChange(
                    "permissionRequest",
                    index,
                    "dateReq"
                  )}
                  InputLabelProps={{ shrink: true }}
                  className="mx-5"
                />
                <TextField
                  label="Fecha de Permiso"
                  type="date"
                  value={request.datePer || ""}
                  onChange={handleArrayChange(
                    "permissionRequest",
                    index,
                    "datePer"
                  )}
                  InputLabelProps={{ shrink: true }}
                  className="mx-5"
                />
                <TextField
                  label="Comentarios"
                  value={request.comment || ""}
                  onChange={handleArrayChange(
                    "permissionRequest",
                    index,
                    "comment"
                  )}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className="mx-5"
                  style={{ width: "50%" }}
                />
                <IconButton
                  onClick={() =>
                    handleRemoveArrayItem("permissionRequest", index)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <Button onClick={() => openAddModal("permissionRequest")}>
              Añadir Solicitud de Permiso
            </Button>
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/personal")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Volver
        </Button>
        <Button
          onClick={() => savePersonal()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar personal
        </Button>
        {personalId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar personal
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que deseas eliminar este personal?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deletePersonal(personalId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Personal deleted successfully",
                      });
                      postLog(
                        "Personal",
                        `delete-${personalId}`,
                        user.fullName
                      );
                      history.push("/personal");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete personal.",
                    });
                  });
              }}
            />
          </>
        )}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {modalBody}
        </Modal>
      </>
    );
}
