import { /* baseClient, */ authClient, API } from "../index";

// Get all localidades
export const getLocalidades = () => {
  return authClient().get(`${API}/localidad`);
};

// Get localidad by id
export const getLocalidadById = (id) => {
  return authClient().get(`${API}/localidad/${id}`);
};

// Delete localidad
export const deleteLocalidad = (id) => {
  return authClient().delete(`${API}/localidad/${id}`);
};

// Create localidad
export const postLocalidad = (localidad) => {
  return authClient().post(`${API}/localidad`, localidad);
};

// Update localidad
export const updateLocalidad = (id, localidad) => {
  return authClient().put(`${API}/localidad/${id}`, localidad);
};
