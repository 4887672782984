import { authClient, API } from "../index";

// Get all campaigns
export const getCampaigns = () => {
  return authClient().get(`${API}/campaign?getDisabled=true`);
};

//Get Brevo Campaigns
export const getBrevoCampaigns = () => {
  return authClient().get(`${API}/campaign/brevo-campaigns`);
};

// Get campaign by id
export const getCampaignById = (id) => {
  return authClient().get(`${API}/campaign/${id}`);
};

// Delete campaign
export const deleteCampaign = (id) => {
  return authClient().delete(`${API}/campaign/${id}`);
};

// Create campaign
export const postCampaign = async (campaign) => {
  return authClient().post(`${API}/campaign`, campaign);
};

// Update campaign
export const updateCampaign = async (id, campaign) => {
  return authClient().put(`${API}/campaign/${id}`, campaign);
};

// // Count all admins
// export const countAdmins = () => {
//   const role = "admin";
//   return authClient().get(`${API}/campaign/count/${role}`);
// };

// Change status campaign
export const changeStatusCampaign = async (id, active) => {
  return authClient().put(`${API}/campaign/change-status/${id}`, { active });
};
