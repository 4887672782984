import { authClient, API } from "../index";

// Get all contacts
export const getContacts = () => {
  return authClient().get(`${API}/contact?getDisabled=true`);
};

// Get contact by id
export const getContactById = (id) => {
  return authClient().get(`${API}/contact/${id}`);
};

// Delete contact
export const deleteContact = (id) => {
  return authClient().delete(`${API}/contact/${id}`);
};

// Create contact
export const postContact = async (contact) => {
  return authClient().post(`${API}/contact`, contact);
};

// Update contact
export const updateContact = async (id, contact) => {
  return authClient().put(`${API}/contact/${id}`, contact);
};

// // Count all admins
// export const countAdmins = () => {
//   const role = "admin"
// 	return authClient().get(`${API}/contact/count/${role}`)
// }

// Change status contact
export const changeStatusContact = async (id, active) => {
  return authClient().put(`${API}/contact/change-status/${id}`, { active });
};
